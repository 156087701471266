import './_search.scss';

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FluxCard } from '../card/FluxCard';
import { FluxForm } from '../form/FluxForm';
import { Button } from '../button/Button';
import { API } from '../../lib/API/Api';
import { DateHelpers } from '../../lib/Helpers/DateHelpers';
import { Table } from '../table/Table';
import { lang } from '../../lib/Localization/language';
import { Icon } from '../icon/Icon';
import { Modal } from '../application/FluxApp';
import { ConfirmationModal } from '../modal/ConfirmationModal/ConfirmationModal';
import { ManageFilters } from './components/ManageFilters';
import { SearchPagination } from './components/SearchPagination';
import { FluxNotice } from '../notification/FluxNotice';
import { NotificationType } from '../notification/FluxNotification';
import { LocalStorage } from '../../lib/Helpers/LocalStorage/LocalStorageHelper';
import { FluxActions, hasAccess } from '../../lib/ActionManager/FluxActions';
import { isNullOrEmpty } from '../../lib/Helpers/StringHelper';
import { InputTypes } from '../form/InputTypes';
import { useSelector } from 'react-redux';
import { SaveSearchResultsAsExcel } from './lib/ExportToExcel';


export const FluxSearch = (props) => {
    const [searchResult, setSearchResult] = useState({ total: 0, recordsPerPage: 20, currentPage: 0, result: null });
    const [postData, setPostData] = useState(null);
    const [fields, setFields] = useState(null);
    const [actions, setActions] = useState([]);

    const [searchModel, setSearchModel] = useState(null);
    const [resultModel, setResultModel] = useState(null);

    const [selected, setSelected] = useState(null);

    const [errors, setErrors] = useState([]);
    const [payload, setPayload] = useState(null);

    const [busy, setBusy] = useState(false);
    const [ready, setReady] = useState(false);

    const [sortingOptions, setSortingOptions] = useState(null);


    const [cacheName, setCacheName] = useState('no-cache');
    const cachedData = useSelector((state) => state.data['search-result-' + cacheName]);



    const tableContext = {
        expandRow: (index, data) => {
            var copy = Object.assign({}, expandedRows);
            copy[index] = data;
            setExpandedRows(copy);
        },
        setData: (data) => {
            var copy = { ...searchResult };
            copy.result = data;
            setSearchResult(copy);
        },
        getData: () => [...(searchResult.result ?? cachedData?.result ?? [])]
    };


    useEffect(() => {
        prepareActions();

        prepareSearchModel();
        prepareResultModel();
    }, []);

    useEffect(() => {
        if (!postData) return;
        if (props.onFilterChange) {
            props.onFilterChange(postData);
        }
    }, [postData])

    useEffect(() => {
        if (props.research != 0) {
            prepareSearchModel(true);
        }
    }, [props.research]);

    useEffect(() => {
        if (!searchModel) return;

        if (searchModel.name) {
            setCacheName(searchModel.name);
        }
    }, [searchModel]);

    useEffect(() => {
        if (!resultModel) return;
    }, [resultModel]);

    useEffect(() => {
        if (!sortingOptions) return;
        submit();
    }, [sortingOptions]);

    useEffect(() => {
        if (!searchResult?.result) return;
    }, [searchResult?.result]);

    useEffect(() => {
        if (!payload) return;
        setSelected(null);
        setBusy(true);
        API.post(props.endpoint, props.onBeforeSearch ? props.onBeforeSearch(payload) : payload, 'Please wait', 'Unable to fetch data.', false, 'search-result-' + props.model?.name).then((result) => {
            setSearchResult(result);
            if (result.exportToExcel) {
                SaveSearchResultsAsExcel(resultModel, props.exportAllData, result);
            }
            setBusy(false);
        }).catch(() => {
            setBusy(false);
        });
    }, [payload]);


    useEffect(() => {
        if (!fields) return;
        if (!ready) {
            setReady(true);
            return;
        }
    });


    useEffect(() => {
        if (!fields) return;
        if (props.searchOnStart) {
            submit();
        }
    }, [ready]);

    const prepareActions = () => {
        if (!props.actions) return;
        var copyOfActions = FluxActions[props.actions];
        if (!copyOfActions) return;
        var result = [];
        copyOfActions.map(x => {
            if (x.anonymous || hasAccess(x.type)) {
                result.push(x);
            }
        });
        setActions(result);
    };

    const createModelCopy = (source) => {
        var copy = Object.assign({}, source);
        copy.fields = [];
        source.fields.map(x => {
            copy.fields.push({ ...x });
        });
        return copy;
    };

    const prepareSearchModel = (thenSearch = false) => {
        if (!props.model) return;
        // check local storage here
        // if the model's name exists in the local storage use it from the local storage, otherwise use it from the props

        var modelFromStorage = LocalStorage.getModel(props.resultModel?.name + '_search');
        var copy = { ...props.model };
        if (modelFromStorage != null) {
            copy.fields = modelFromStorage.fields;
        }

        copy.fields = copy.fields.filter(x => !x.optional);
        if (props.onPrepareModel) {
            copy = props.onPrepareModel(createModelCopy(copy));
        }
        setSearchModel(copy);
        if (thenSearch) {
            submit(copy);
        }
    };

    const prepareResultModel = () => {
        if (!props.resultModel) return;
        // check local storage here
        // if the model's name exists in the local storage use it from the local storage, otherwise use it from the props

        var modelFromStorage = LocalStorage.getModel(props.resultModel?.name + '_result');
        var copy = { ...props.resultModel };
        if (modelFromStorage != null) {
            copy.fields = modelFromStorage.fields;
        }
        if (props.onPrepareResultModel) copy = props.onPrepareResultModel(createModelCopy(copy));
        setResultModel(copy);
    };


    const submit = (changedFields = null) => {
        var query = [];
        var errorsCopy = [];

        if (!fields) return;

        fields.map(field => {
            if (field.required) {
                var requiredValue = postData[field.name];
                if (isNullOrEmpty(requiredValue) || requiredValue?.length === 0) {
                    errorsCopy.push({ field: field, error: 'Field can not be empty.' });
                    return;
                }
            }
            var value = postData[field.name];
            if (changedFields && changedFields?.fields && changedFields.fields.length > 0) {
                var fieldData = changedFields.fields.find(x => x.name == field.name);
                if (fieldData) {
                    value = fieldData.value;
                } else {
                    value = null;
                }
            }
            if (value && field.multiple) value = value.toString();
            if (!field.between) {
                if (value === undefined && !isNullOrEmpty(field.value)) {
                    value = field.value;
                }
                if (value !== undefined) {
                    if (field.type == InputTypes.Number && isNaN(value)) {
                        value = null;
                    }
                    if (isNullOrEmpty(value) || value?.length === 0) {

                    } else {
                        if (field.type == InputTypes.DateTime) {
                            var dateValue = DateHelpers.addHours(new Date(field.value), DateHelpers.timeDiff()).toISOString();
                            query.push({ name: field.name, value: dateValue, multiple: field.multiple, exact: field.exact });
                        } else {
                            if (field.type == InputTypes.Select) {
                                if (field.nullable && value == null) {
                                    // do not add
                                    return;
                                }
                            } else {
                                value = Array.isArray(value) ? value.join(',') : value;
                            }
                            query.push({ name: field.name, value: value, multiple: field.multiple, exact: field.exact });
                        }
                    }
                }
            } else {
                var min = postData[field.name + '_min'];
                var max = postData[field.name + '_max'];

                if (field.type == InputTypes.DateTime) {
                    min = DateHelpers.addHours(new Date(min), DateHelpers.timeDiff()).toISOString();
                    max = DateHelpers.addHours(new Date(max), DateHelpers.timeDiff()).toISOString();
                }
                if (min !== undefined || max !== undefined) {
                    query.push({ name: field.name, between: true, min: min !== undefined ? min : null, max: max !== undefined ? max : null, exact: field.exact });
                }
            }
        });

        if (errorsCopy.length) {
            setErrors(errorsCopy);
            return;
        }

        setErrors(null);
        if (props.endpoint) {
            const copy = {
                fields: query,
                recordsPerPage: 20,
                sorting: sortingOptions,
                timeDiff: DateHelpers.timeDiff(),
                timeZone: DateHelpers.timeZone()
            };

            setPayload(copy);
        }
    };

    const renderActions = () => {
        return <buttons className={`tight-buttons rounded-corners${!selected ? ' disabled' : ''}`}>
            {actions.map(action => {
                return <Button title={action.caption} key={action} flat onClick={props.onActionClicked ? () => props.onActionClicked(action, selected.row, selected) : null} />;
            })}
        </buttons>;
    };

    const openFilters = () => {
        var copy = [];
        searchModel.fields.map(x => {
            copy.push({ name: x.name, title: x.title ?? x.name, enabled: !x.hidden });
        });

        var filteredFields = null;
        Modal.open(<ConfirmationModal title='Manage filters' onConfirm={() => {
            var result = { ...searchModel };
            result.fields = [];
            searchModel.fields.map(x => {
                var newField = { ...x };
                newField.hidden = !filteredFields.find(y => y.name == x.name).enabled;
                result.fields.push(newField);
            });


            if (searchModel.name) {
                LocalStorage.saveModel(searchModel.name + '_search', result);
            }

            result.key = Date.now();
            setSearchModel(result);
        }}>
            <ManageFilters model={props.model} fields={copy} onUpdate={(fields) => {
                filteredFields = fields;
            }} />
        </ConfirmationModal>);
    };

    const canExportToExcel = () => {
        if (props.exportToExcel !== undefined) {
            if (props.exportToExcel) {
                exportToExcel();
            }
        } else {
            exportToExcel();
        }
    };

    const exportToExcel = () => {
        var copy = { ...payload };
        copy.exportToExcel = true;
        setPayload(copy);
    };

    const renderErrors = () => {
        var errorList = errors.map(x => {
            return <flex className='gap-5' key={x}><label>{lang(x.field.title ?? x.field.display)}</label><span>{x.error}</span></flex>;
        });

        return <FluxNotice type={NotificationType.Error} title='Please correct the errors.' description={errorList} />
    };


    const arrangeResultColumns = () => {
        var copy = [];
        resultModel.fields.map(x => {
            copy.push({ name: x.name, title: x.title ?? x.name, enabled: !x.hidden });
        });

        var filteredFields = null;
        Modal.open(<ConfirmationModal title='Arrange result columns' onConfirm={() => {
            var result = { ...resultModel };
            result.fields = [];
            resultModel.fields.map(x => {
                var newField = { ...x };
                newField.hidden = !filteredFields.find(y => y.name == x.name).enabled;
                result.fields.push(newField);
            });


            if (resultModel.name) {
                LocalStorage.saveModel(resultModel.name + '_result', result);
            }


            result.key = Date.now();
            setResultModel(result);
        }}>
            <ManageFilters model={props.model} fields={copy} onUpdate={(fields) => {
                filteredFields = fields;
            }} />
        </ConfirmationModal>);

    };

    if (!searchModel) return <></>;
    if (!resultModel) return <></>;

    return <FluxCard title={props.title} className={`flux-search${busy ? ' busy' : ''}${props.className ? ' ' + props.className : ''}`}>
        <flex className='vertical gap-10'>
            <FluxForm
                {...props}
                key={searchModel?.key}
                model={searchModel}
                data={postData}
                onReady={(f) => setFields(f)}
                onChange={(data) => {
                    setPostData(data);
                }}
                className={!props.filters ? 'hidden' : 'horizontal'} buttons={<buttons className='gap-5'>
                    <Button title='Search' className='success' onClick={() => submit()} />
                    <flex className='align-right gap-5 clickable' onClick={() => openFilters()}>
                        <Icon icon='grid-2-plus' type='fa-solid' /><span>{lang('%1/%2 Manage filters', [fields?.filter(x => !x.hidden).length, props.model.fields.length])}</span>
                    </flex>
                </buttons>}>
                {errors?.length > 0 && renderErrors()}
            </FluxForm>
            <flex className='gap-10 vertical border-top padding-top'>
                {searchResult?.result && searchResult.result.length == 0 && <FluxNotice type={NotificationType.Warning} title='No records found' description='Please check search filters.' />}
                <flex className='gap-10'>
                    <flex className='vertical'>
                        <span className='bold medium'>{lang(searchResult?.result && cachedData?.result ? 'Cached Results' : 'Results')}</span>
                        <span>{lang('Total %1 items', [searchResult ? searchResult.total : cachedData?.total])}</span>
                    </flex>
                    <flex className='center gap-10'>
                        {renderActions()}
                    </flex>
                    <flex className='gap-20'>
                        <flex className='gap-5 clickable' onClick={() => {
                            arrangeResultColumns();
                        }}>
                            <Icon icon='filter-list' type='fa-solid' /><span>{lang('Columns')}</span>
                        </flex>
                        <flex className='gap-5 clickable' onClick={() => canExportToExcel()}>
                            <Icon icon='file-csv' type='fa-solid' /><span>{lang('Export')}</span>
                        </flex>
                    </flex>
                </flex>
            </flex>
            <Table
                {...props}
                className={'fx-borders fx-shadow'}
                context={props.context} model={resultModel} data={searchResult?.result ?? cachedData?.result ?? []}
                options={{
                    sorting: {
                        enabled: true,
                        field: searchResult?.sorting,
                        onSortingChange: (s) => {
                            setSortingOptions(s);
                        }
                    }
                }}
                onRowClick={(row, element, index) => {
                    setSelected({ row: row, element: element, index: index, tableContext: tableContext });
                }}
                onDoubleClick={props.onDoubleClick}
                footer={searchResult?.footer}
                key={resultModel?.key}
            />
            <SearchPagination {...searchResult} onPageClicked={(index) => {
                var copy = { ...payload };
                copy.currentPage = index;
                setPayload(copy);
            }} />
        </flex>
    </FluxCard>;
};

FluxSearch.defaultProps = {
    columns: 5,
    filters: true,
    exportToExcel: true,
    research: 0
};

FluxSearch.propTypes = {
    context: PropTypes.object,
    model: PropTypes.object,
    filters: PropTypes.bool,
    actions: PropTypes.string,
    resultModel: PropTypes.object,
    endpoint: PropTypes.string,
    tableClassName: PropTypes.string,
    title: PropTypes.any,
    exportToExcel: PropTypes.bool,
    exportAllData: PropTypes.bool,
    searchOnStart: PropTypes.bool,
    onPrepareModel: PropTypes.func,
    onPrepareResultModel: PropTypes.func,
    onFilterChange: PropTypes.func,
    onActionClicked: PropTypes.func,
    onDoubleClick: PropTypes.func,
    onBeforeSearch: PropTypes.func,
    research: PropTypes.number
};