/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import { Modal } from '../../../../components/application/FluxApp';
import { Button } from '../../../../components/button/Button';
import { ActionButton, FluxCard } from '../../../../components/card/FluxCard';
import { SelectPaymentProviders } from '../../../../components/dataControls/SelectPaymentProviders';
import { FluxForm } from '../../../../components/form/FluxForm';
import { InputTypes } from '../../../../components/form/InputTypes';
import { FluxModal } from '../../../../components/modal/FluxModal';
import { FluxTab } from '../../../../components/tab/FluxTab';
import { Table } from '../../../../components/table/Table';
import { PromotionEndpoints } from '../../../../lib/API/Endpoints';
import { API } from '../../../../lib/API/Api';
import ReactSelect from 'react-select';

const model = {
    fields: [
        {
            name: 'AutomaticDeposit',
            title: 'Deposit bonus amount to players wallet after successfull deposit from the providers.',
            type: InputTypes.Bool
        },
        {
            name: 'ConsumeOnlyOnce',
            title: 'Can be consumed only once.',
            type: InputTypes.Bool
        },
        {
            name: 'prizeType',
            title: 'Prize Type',
            type: InputTypes.Select,
            values: {
                'BonusMoney': 'Award bonus money that players must use to complete specified rollover requirements before withdrawal.',
                'FreeBet': 'Give players a free bet directly upon deposit, without any rollover requirements.'
            }
        }
    ]
};



const PrizeTypes = {
    'BonusMoney': 'BonusMoney',
    'FreeBet': 'FreeBet'
};

export const PaymentGatewayConfiguration = (props) => {
    let { promotion, currencies, onChange } = { ...props };
    const [selectedCurrency, selectCurrency] = useState(currencies[0]);
    const [data, setData] = useState(null);

    const [freebets, setFreeBets] = useState(null);


    useEffect(() => {
        API.post(PromotionEndpoints.List, {}).then((result) => {
            var p = result.result.filter(x => x.Type == 17 || x.Type == 13).sort((a, b) => a.Name > b.Name ? 1 : -1);
            setFreeBets(p);
        });
    }, []);

    useEffect(() => {
        if (!freebets) return;
        var copy = promotion.Configuration.deposit ?? { Enabled: false, AutomaticDeposit: false, ConsumeOnlyOnce: false, Providers: {}, DepositLimits: {} };
        if (!copy.prizeType) {
            copy.prizeType = PrizeTypes.BonusMoney;
        }

        if (copy.depostLimits) {
            copy.DepositLimits = copy.depostLimits;
            delete copy.depositLimits;
        }
        setData(copy);
    }, [freebets]);

    useEffect(() => {
        if (!data) return;
        var copy = { ...data };
        if (copy.prizeType == PrizeTypes.FreeBet && copy.DepositLimits) {
            Object.keys(copy.DepositLimits).map(x => {
                if (copy.DepositLimits?.[x] instanceof Array) {
                    for (let i = 0; i < copy.DepositLimits[x].length; i++) {
                        const element = copy.DepositLimits[x][i];
                        if (!element.freeBet) {
                            if (freebetOptions[0]) {
                                element.freeBet = freebetOptions[0].value;
                                element.promotionType = freebets.find(x => x.Id == freebetOptions[0].value).Type;
                            }
                        }
                    }
                } else {
                    copy.DepositLimits[x].map(y => {
                        if (!y.freeBet) {
                            if (freebetOptions[0]) {
                                element.freeBet = freebetOptions[0].value;
                                element.promotionType = freebets.find(x => x.Id == freebetOptions[0].value).Type;
                            }
                        }
                    });
                }
            });
        }


        promotion.Configuration.deposit = copy;


        onChange({ ...promotion });
    }, [data]);

    useEffect(() => {

    }, [data?.prizeType]);

    const addProvider = () => {
        var provider = null;
        Modal.open(<FluxModal title='Select Provider' footer={<flex className='gap-10 center'>
            <Button className='success' title='Add' onClick={() => {
                Modal.close();
                var copy = { ...data };
                if (!copy.Providers) {
                    copy.Providers = {

                    };
                }
                copy.Providers[provider.id] = {
                    id: provider.id,
                    name: provider.name
                };
                setData(copy);
            }} />
            <Button title='Cancel' onClick={() => Modal.close()} />
        </flex>}>
            <SelectPaymentProviders filter={(x) => {
                if (data.Providers && data.Providers[x.id]) {
                    return false;
                }
                return true;
            }} onChange={(data) => provider = data} />
        </FluxModal>);
    };

    const removeProvider = (provider) => {
        var copy = { ...data };
        delete copy.Providers[provider.id];
        setData(copy);
    };


    const addNewLimit = () => {
        var copy = { ...data };
        if (Array.isArray(copy.DepositLimits)) {
            copy.DepositLimits = {};
        }
        if (!copy.DepositLimits) {
            copy.DepositLimits = {};
        }
        if (!copy.DepositLimits[selectedCurrency.CurrencyCode]) {
            copy.DepositLimits[selectedCurrency.CurrencyCode] = [];
        }

        copy.DepositLimits[selectedCurrency.CurrencyCode].push({
            from: 0,
            to: 0,
            percentage: 0
        });

        setData(copy);
    };


    const removeLimit = (row) => {
        var copy = { ...data };
        copy.DepositLimits[selectedCurrency.CurrencyCode] = copy.DepositLimits[selectedCurrency.CurrencyCode].filter(x => x !== row);
        setData(copy);
    };

    const updateLimit = (row, e) => {
        var copy = { ...data };
        if (!copy.DepositLimits) {
            copy.DepositLimits = {};
        }
        if (!copy.DepositLimits[selectedCurrency.CurrencyCode]) {
            copy.DepositLimits[selectedCurrency.CurrencyCode] = [];
        }

        var limit = copy.DepositLimits[selectedCurrency.CurrencyCode].find(x => x === row);
        if (!limit) {
            limit = { ...row };
            copy.DepositLimits[selectedCurrency.CurrencyCode].push(limit);
        }
        var value = parseFloat(e.target.value);
        if (isNaN(value)) value = 0;
        if (e.target.name == 'percentage') {
            e.target.value = value;
        }
        limit[e.target.name] = value;
        if (!row.freeBet && data.prizeType == PrizeTypes.FreeBet) {
            if (freebetOptions[0]) {
                limit.freeBet = freebetOptions[0].value;
                limit.promotionType = freebets.find(x => x.Id == freebetOptions[0].value).Type;
            }
        }
        if (e.target.name == 'freeBet') {
            limit.promotionType = freebets.find(x => x.Id.toString() == e.target.value.toString()).Type;
        }
        setData(copy);
    };




    if (!freebets) return <></>;
    if (!data) return <></>;
    if (data.prizeType == PrizeTypes.FreeBet && freebets.length == 0) return <></>;


    var freebetOptions = freebets.map(x => {
        return {
            label: x.Name,
            value: x.Id
        }
    });


    return <flex className='vertical gap-10' key={data?.prizeType}>
        <FluxCard>
            <FluxForm data={data} model={model} columns={2} buttons={null} onChange={(d) => setData(d)} />
        </FluxCard>
        <FluxCard title='Providers' buttons={<ActionButton title='Add' icon='plus-square' onClick={() => addProvider()} />}>
            <Table className='fx-borders fx-shadow' model={{
                fields: [
                    {
                        name: 'id',
                        title: 'Id'
                    },
                    {
                        name: 'name',
                        title: 'Name',
                    }
                ]
            }} data={Object.values(data.Providers ?? [])} buttons={(row) => {
                return <Button title='Remove' className={'warning'} onClick={() => removeProvider(row)} />;
            }} />
        </FluxCard>
        {data.prizeType == PrizeTypes.BonusMoney &&
            <FluxCard title='Trigger bonus money using the limits' buttons={<ActionButton title='Add' icon='plus-square' onClick={() => addNewLimit()} />}>
                <div className='flex vertical gap-10'>
                    <FluxTab buttons={currencies.map(x => {
                        return { title: x.CurrencyCode };
                    })} onChange={(index) => selectCurrency(currencies[index])} className='gap-10 fx-shadow fx-radius rounded-buttons overflow-hidden'>
                    </FluxTab>
                    <Table className='fx-borders fx-shadow'
                        key={selectedCurrency.CurrencyCode}
                        model={{
                            fields: [
                                {
                                    name: 'from',
                                    title: 'From',
                                    render: (val, row, field) => {
                                        return <input type='number' name={field.name}
                                            defaultValue={val}
                                            onFocus={(e) => {
                                                e.target.setAttribute('data', e.target.value);
                                                e.target.value = '';
                                            }}
                                            onBlur={(e) => {
                                                if (e.target.value == '') {
                                                    e.target.value = e.target.getAttribute('data') ?? 0;
                                                }
                                            }}
                                            onChangeCapture={(e) => {
                                                updateLimit(row, e);
                                            }} />
                                    }
                                },
                                {
                                    name: 'to',
                                    title: 'To',
                                    render: (val, row, field) => {
                                        return <input type='number' name={field.name}
                                            defaultValue={val}
                                            onFocus={(e) => {
                                                e.target.setAttribute('data', e.target.value);
                                                e.target.value = '';
                                            }}
                                            onBlur={(e) => {
                                                if (e.target.value == '') {
                                                    e.target.value = e.target.getAttribute('data') ?? 0;
                                                }
                                            }}
                                            onChangeCapture={(e) => {
                                                updateLimit(row, e);
                                            }} />
                                    }
                                },
                                {
                                    name: 'percentage',
                                    title: 'Percentage',
                                    render: (val, row, field) => {
                                        return <input type='number' name={field.name}
                                            defaultValue={val}
                                            max={100}
                                            onFocus={(e) => {
                                                e.target.setAttribute('data', e.target.value);
                                                e.target.value = '';
                                            }}
                                            onBlur={(e) => {
                                                if (e.target.value == '') {
                                                    e.target.value = e.target.getAttribute('data') ?? 0;
                                                }
                                            }}
                                            onChangeCapture={(e) => {
                                                updateLimit(row, e);
                                            }} />
                                    }
                                }

                            ]
                        }} data={(data.DepositLimits && data.DepositLimits[selectedCurrency.CurrencyCode]) ? data.DepositLimits[selectedCurrency.CurrencyCode] : []} buttons={(row) => {
                            return <Button title='Remove' className={'warning'} onClick={() => removeLimit(row)} />;
                        }} />
                </div>
            </FluxCard>
        }
        {data.prizeType == PrizeTypes.FreeBet &&
            <FluxCard title='Trigger free bet using the limits' buttons={<ActionButton title='Add' icon='plus-square' onClick={() => addNewLimit()} />}>
                <div className='flex vertical gap-10'>
                    <FluxTab buttons={currencies.map(x => {
                        return { title: x.CurrencyCode };
                    })} onChange={(index) => selectCurrency(currencies[index])} className='gap-10 fx-shadow fx-radius rounded-buttons overflow-hidden'>
                    </FluxTab>
                    <Table className='fx-borders fx-shadow'
                        key={selectedCurrency.CurrencyCode}
                        model={{
                            fields: [
                                {
                                    name: 'from',
                                    title: 'From',
                                    render: (val, row, field) => {
                                        return <input type='number' name={field.name}
                                            defaultValue={val}
                                            onFocus={(e) => {
                                                e.target.setAttribute('data', e.target.value);
                                                e.target.value = '';
                                            }}
                                            onBlur={(e) => {
                                                if (e.target.value == '') {
                                                    e.target.value = e.target.getAttribute('data') ?? 0;
                                                }
                                            }}
                                            onChangeCapture={(e) => {
                                                updateLimit(row, e);
                                            }} />
                                    }
                                },
                                {
                                    name: 'to',
                                    title: 'To',
                                    render: (val, row, field) => {
                                        return <input type='number' name={field.name}
                                            defaultValue={val}
                                            onFocus={(e) => {
                                                e.target.setAttribute('data', e.target.value);
                                                e.target.value = '';
                                            }}
                                            onBlur={(e) => {
                                                if (e.target.value == '') {
                                                    e.target.value = e.target.getAttribute('data') ?? 0;
                                                }
                                            }}
                                            onChangeCapture={(e) => {
                                                updateLimit(row, e);
                                            }} />
                                    }
                                },
                                {
                                    name: 'percentage',
                                    title: 'Percentage',
                                    render: (val, row, field) => {
                                        return <input type='number' name={field.name}
                                            defaultValue={val}
                                            max={100}
                                            onFocus={(e) => {
                                                e.target.setAttribute('data', e.target.value);
                                                e.target.value = '';
                                            }}
                                            onBlur={(e) => {
                                                if (e.target.value == '') {
                                                    e.target.value = e.target.getAttribute('data') ?? 0;
                                                }
                                            }}
                                            onChangeCapture={(e) => {
                                                updateLimit(row, e);
                                            }} />
                                    }
                                },
                                {
                                    name: 'freeBet',
                                    title: 'Free Bet',
                                    hidden: data.prizeType != PrizeTypes.FreeBet,
                                    render: (val, row) => {
                                        var selectedFreeBetOption = freebetOptions[0];
                                        if (val) {
                                            selectedFreeBetOption = freebetOptions.find(x => x.value.toString() == val);
                                        }

                                        return <ReactSelect
                                            className='react-select'
                                            styles={{ menuPortal: base => ({ ...base, zIndex: 100 }) }}
                                            menuPortalTarget={document.body}
                                            defaultValue={selectedFreeBetOption}
                                            options={freebetOptions}
                                            onChange={(e) => {
                                                updateLimit(row, {
                                                    target: {
                                                        name: 'freeBet',
                                                        value: e.value
                                                    }
                                                });
                                            }}
                                            name={'freebet'}
                                            hideSelectedOptions={false}
                                            closeMenuOnSelect={true}
                                            isClearable={false}
                                            isSearchable={false}
                                        />
                                    }
                                }

                            ]
                        }} data={(data.DepositLimits && data.DepositLimits[selectedCurrency.CurrencyCode]) ? data.DepositLimits[selectedCurrency.CurrencyCode] : []} buttons={(row) => {
                            return <Button title='Remove' className={'warning'} onClick={() => removeLimit(row)} />;
                        }} />
                </div>
            </FluxCard>
        }
    </flex>;
};