/* eslint-disable react/display-name */
/* eslint-disable react/react-in-jsx-scope */

import React from 'react';
import { InputTypes } from '../../components/form/InputTypes';
import { DateHelpers } from '../../lib/Helpers/DateHelpers';
import { SportsTicketStatus } from '../Enums/BettingEnums';
import { footerCurrencyValues } from '../../lib/Helpers/Reports/Footer';
import { Currency } from '../../components/currency/Currency';

export const SearchModelHorseBettingReport = {
    fields: [
        {
            name: 'OperatorId',
            title: 'Operator',
            type: InputTypes.Select,
            exact: true,
            nullable: true,
            data: {
                source: 'operators',
                key: 'Id',
                title: ['Name']
            }
        },
        {
            name: 'EntryDate',
            title: 'Date',
            type: InputTypes.DateTime,
            nullable: true,
            between: true,
            value: {
                start: DateHelpers.startOfWeek(),
                end: DateHelpers.tonight()
            }
        },
        {
            name: 'Status',
            type: InputTypes.Select,
            nullable: true,
            values: SportsTicketStatus
        },
        {
            name: 'ExternalTicketId',
            title: 'Ticket Id',
            type: InputTypes.Number
        },
        {
            name: 'EventId',
            title: 'Event Id',
            exact: true,
        },
        {
            name: 'PlayerId',
            title: 'Player Id',
            type: InputTypes.Number
        },
        {
            name: 'Username'
        },
        {
            name: 'Code'
        },
        {
            name: 'RetailCode',
            title: 'Retail Code'
        },
        {
            name: 'Group',
            title: 'Player Group',
            type: InputTypes.Select,
            exact: true,
            nullable: true,
            data: {
                source: 'myPlayerGroups',
                key: 'Id',
                title: ['Name']
            }
        },
        {
            name: 'Retails',
            title: 'Only Retails',
            type: InputTypes.Bool
        }
    ]
};

export const ResultModelHorseBettingReport = {
    fields: [
        {
            name: 'Country'
        },
        {
            name: 'Slips',
        },
        {
            name: 'Currency',
        },
        {
            name: 'OverallStake',
            title: 'Overall Stake',
            type: InputTypes.Number,
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        },
        {
            name: 'OverallWin',
            title: 'Overall Win',
            type: InputTypes.Number,
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        },
        {
            name: 'OverallGGR',
            title: 'Overall GGR',
            type: InputTypes.Number,
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        },
        {
            name: 'Stake',
            title: 'Stake',
            type: InputTypes.Number,
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        },
        {
            name: 'Win',
            title: 'Win',
            type: InputTypes.Number,
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        },
        {
            name: 'GGR',
            title: 'GGR',
            type: InputTypes.Number,
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        },
        {
            name: 'Liability',
            title: 'Liability',
            type: InputTypes.Number,
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        },
        {
            name: 'BonusBet',
            title: 'Bonus Bet',
            type: InputTypes.Number,
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        },
        {
            name: 'BonusWin',
            title: 'Bonus Win',
            type: InputTypes.Number,
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        },
        {
            name: 'BonusLiability',
            title: 'Bonus Liability',
            type: InputTypes.Number,
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        },
        {
            name: 'BonusGGR',
            title: 'Bonus GGR',
            type: InputTypes.Number,
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        }
    ]
};
