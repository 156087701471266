/* eslint-disable react/display-name */
import React from 'react';
import { ReportEndpoints } from '../../../../v2/Lib/Api/Api';
import { FluxSearch } from '../../../components/search/FluxSearch';

import { InputTypes } from '../../../components/form/InputTypes';
import { footerCurrencyValues } from '../../../lib/Helpers/Reports/Footer';
import { Currency } from '../../../components/currency/Currency';
import { DateHelpers } from '../../../lib/Helpers/DateHelpers';
import { SportsTicketStatus } from '../../../lib/Enums/BettingEnums';
import { NavLink } from 'react-router-dom';


export const searchModel = {
    fields: [
        {
            name: 'OperatorId',
            title: 'Operator',
            type: InputTypes.Select,
            exact: true,
            nullable: true,
            data: {
                source: 'operators',
                key: 'Id',
                title: ['Name']
            }
        },
        {
            name: 'EntryDate',
            title: 'Date',
            type: InputTypes.DateTime,
            nullable: true,
            between: true,
            value: {
                start: DateHelpers.startOfWeek(),
                end: DateHelpers.tonight()
            }
        },
        {
            name: 'Status',
            type: InputTypes.Select,
            nullable: true,
            values: SportsTicketStatus
        },
        {
            name: 'ExternalTicketId',
            title: 'Ticket Id',
            type: InputTypes.Number
        },
        {
            name: 'PlayerId',
            title: 'Player Id',
            type: InputTypes.Number
        },
        {
            name: 'Username'
        },
        {
            name: 'Code'
        },
        {
            name: 'RetailId',
            title: 'Retail Id',
            nullable: true,
            type: InputTypes.Number
        },
        {
            name: 'RetailCode',
            title: 'Retail Code'
        },
        {
            name: 'Group',
            title: 'Player Group',
            type: InputTypes.Select,
            exact: true,
            nullable: true,
            data: {
                source: 'myPlayerGroups',
                key: 'Id',
                title: ['Name']
            }
        }
    ]
};

const resultModel = {
    fields: [
        {
            name: 'Username',
            render: (val, row) => {
                return <NavLink to={`/players/search?&id=${row.PlayerId}`}>{val}</NavLink>;
            }
        },
        {
            name: 'PlayerId',
            title: 'Player Id'
        },
        {
            name: 'Slips',
        },
        {
            name: 'Currency',
        },
        {
            name: 'Bet',
            title: 'Stakes',
            render: (val, row) => <Currency amount={val} sign={row.Currency} />,
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)

        },
        {
            name: 'BetsConverted',
            title: 'Stakes in (USD)',
            render: (val) => <Currency amount={val} sign={'USD'} />,
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        },
        {
            name: 'Win',
            title: 'Win',
            render: (val, row) => <Currency amount={val} sign={row.Currency} />,
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)

        },
        {
            name: 'WinsConverted',
            title: 'Wins in (USD)',
            render: (val) => <Currency amount={val} sign={'USD'} />,
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        },
        {
            name: 'GGR',
            title: 'GGR',
            type: InputTypes.Number,
            render: (val, row) => <Currency amount={row.BetsConverted - row.WinsConverted} sign={'USD'} />,
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        }
    ]
};




export const ReportPlayersHorseGreyhound = () => {
    return <FluxSearch
        model={searchModel}
        endpoint={ReportEndpoints.HorseGreyHoundPlayersReport}
        resultModel={resultModel}
    />;
};