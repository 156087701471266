/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { API } from '../../../../../../lib/API/Api';
import { DataEndpoints, PlayerEndpoints, RiskLimitEndpoints } from '../../../../../../lib/API/Endpoints';
import { lang } from '../../../../../../lib/Localization/language';
import { Modal } from '../../../../../application/FluxApp';
import { Button } from '../../../../../button/Button';
import { FluxCard } from '../../../../../card/FluxCard';
import { MultipleSelectInput } from '../../../../../form/Components/MultipleSelectInput';
import { FluxForm } from '../../../../../form/FluxForm';
import { InputTypes } from '../../../../../form/InputTypes';
import { FluxModal } from '../../../../../modal/FluxModal';
import { Table } from '../../../../../table/Table';

const sortGroups = (a, b) => a.Name > b.Name ? 1 : -1;
const field = {
    values: {
        'All': 1,
        'Online': 2,
        'Retail': 3
    },
    value: 1
};

const tableModel = {
    fields: [
        { name: 'Id', hidden: true },
        { name: 'Name', title: 'Group Name' },
        { name: 'Description', title: 'Group Description' },
        {
            name: 'Retail',
            title: 'Online/Retail',
            type: InputTypes.Bool,
            render: (val) => {
                return <flex>
                    <span>{lang(val ? 'Retail' : 'Online')}</span>
                </flex>;
            }
        },
        // {
        //     name: 'LiabilityLimitStake',
        //     title: 'Liability Limit Factor',
        //     render: (val, row, field, context) => <flex key={row.Id} className="gap-5">
        //         <input type='number'
        //             name={field.name}
        //             min={0}
        //             step={0.01}
        //             defaultValue={val}
        //             onChange={(e) => context.update(e, row)} />
        //         <span>% Factor</span>
        //     </flex>
        // },
        {
            name: 'StakeLimitStake',
            title: 'Stake Limit Factor',
            render: (val, row, field, context) => <flex key={row.Id} className="gap-5">
                <input type='number'
                    name={field.name}
                    min={0}
                    step={0.01}
                    defaultValue={val}
                    onChange={(e) => context.update(e, row)} />
                <span>% Factor</span>
            </flex>
        },
        // {
        //     name: 'MaxPayoutStake',
        //     title: 'Max Payout Factor',
        //     render: (val, row, field, context) => <flex key={row.Id} className="gap-5">
        //         <input type='number'
        //             name={field.name}
        //             min={0}
        //             step={0.01}
        //             defaultValue={val}
        //             onChange={(e) => context.update(e, row)} />
        //         <span>% Factor</span>
        //     </flex>
        // },
    ]
};


export const RiskTemplateStakeFactoring = () => {
    const user = useSelector((state) => state.user);
    const [groups, setGroups] = useState([]);
    const [showFiltersMenu, toggleFilters] = useState(false);
    const [searchFilter, setSearchFilter] = useState(null);
    const [onlineFilter, setOnlineFilter] = useState(1);

    useEffect(() => {
        fetchGroups();
    }, []);

    const getData = () => {
        var res = groups;

        if (searchFilter) {
            var text = searchFilter.toLowerCase();
            res = groups.filter(x => x.Name.toLowerCase().includes(text));
            if (onlineFilter == 2) {
                res = res.filter(x => x.Retail == false);
            } else if (onlineFilter == 3) {
                res = res.filter(x => x.Retail == true);
            }
        }
        else {
            if (onlineFilter == 2) {
                res = res.filter(x => x.Retail == false);
            } else if (onlineFilter == 3) {
                res = res.filter(x => x.Retail == true);
            }
        }
        return res;
    };

    const fetchGroups = () => {
        API.post(DataEndpoints.Groups.indexOf('/') == 0 ? DataEndpoints.Groups : `/api/data/${DataEndpoints.Groups}`, {}, null, null, false, null, false).then((result) => {
            API.post(RiskLimitEndpoints.GetStakeFactoring(user.loginInformation.UserPrivileges.PointOfView)).then((stakeResults) => {
                result.result.map(x => {
                    const stakeData = stakeResults.result.find(y => y.id == x.Id);
                    if (stakeData) {
                        x['LiabilityLimitStake'] = stakeData.liabilityLimitStake;
                        x['StakeLimitStake'] = stakeData.stakeLimitStake;
                        x['MaxPayoutStake'] = stakeData.maxPayoutStake;
                    }
                    return x;
                });
                setGroups(result.result.sort((a, b) => sortGroups(a, b)));
            });
        });
    };

    const roundToHundredth = (value) => {
        if (value >= 0.001 && value <= 0.009) {
            return 0.01;
        }
        return Math.round(value * 100) / 100;
    };

    const context = {
        update: (e, row) => {
            var copy = groups;
            var field = copy.find(x => x.Id == row.Id);
            var value = e.target.value;
            if (e.target.value == field[e.target.name]) return;
            if (e.target.type == 'number') {
                value = roundToHundredth(parseFloat(value));
                e.target.value = value;
            }
            field[e.target.name] = value;
            setGroups(copy.sort((a, b) => sortGroups(a, b)));
        }
    };

    const save = () => {
        API.post(RiskLimitEndpoints.SaveStakeFactoring(user.loginInformation.UserPrivileges.PointOfView), groups)
            .then(() => {
                // not sure to do what here.
            });
    };

    const showAddNewGroupModal = (onSaveComplete) => {
        Modal.open(<FluxModal title={'Add New group'} >
            <FluxCard>
                <FluxForm
                    model={{
                        fields: [
                            {
                                name: 'Name',
                                title: 'Name',
                                required: true
                            },
                            {
                                name: 'Description',
                                title: 'Description',
                                required: true
                            }
                        ]
                    }}
                    endpoint={PlayerEndpoints.SavePlayerGroup}
                    confirm={true}
                    confirmTitle='Create new group'
                    confirmMessage='Please confirm before creating new group'
                    onCancel={() => {
                        onSaveComplete();
                    }}
                    onSubmitComplete={() => {
                        onSaveComplete();
                    }} />
            </FluxCard>
        </FluxModal >);
    };

    return <FluxCard title='Template Stake Factoring'
        buttons={<flex className='gap-5 align-right'>
            <Button title={showFiltersMenu ? 'Close Filters' : 'Open Filters'} onClick={() => toggleFilters(!showFiltersMenu)} />
            <Button className={'marginRight'} title='Add Group' onClick={() => showAddNewGroupModal(() => fetchGroups())} />
            <Button className={'success marginRight'} title='Save' onClick={() => save()} />
        </flex>}>
        {
            showFiltersMenu &&
            <flex className='padding gap-10 border'>
                <flex className='padding border-bottom gap-10 fx-shadow' >
                    <span className='bold margin-end no-wrap'>{lang('Search in tree')}</span>
                    <input type='text' className='w-100' placeholder='Search' defaultValue={null}
                        onChange={(e) => setSearchFilter(e.target.value)} />
                </flex>
                <flex className={'padding border-bottom gap-10 fx-shadow'}>
                    <span className='bold'>{lang('Type')}</span>
                    <MultipleSelectInput
                        data={1}
                        field={field}
                        onChange={(a, b) => setOnlineFilter(b)} />
                </flex>
                <hr></hr>
            </flex>
        }
        <Table
            className={'fx-borders fx-shadow limits-table'}
            key={'Id'}
            model={tableModel}
            context={context}
            data={getData()} />
    </FluxCard >;
};