/* eslint-disable react/display-name */
import React from 'react';
import { ReportEndpoints } from '../../../../v2/Lib/Api/Api';
import { Currency } from '../../../components/currency/Currency';
import { InputTypes } from '../../../components/form/InputTypes';
import { FluxSearch } from '../../../components/search/FluxSearch';
import { TransactionTypes } from '../../../lib/Enums/TransactionEnums';
import { DateHelpers } from '../../../lib/Helpers/DateHelpers';
import { gotoProfile } from '../../../lib/Helpers/NavigationHelper';
import { CurrencyFilterField } from '../../../lib/Models/CurrencyModel';

export const searchModel = {
    fields: [
        {
            name: 'OperatorId',
            title: 'Operator',
            type: InputTypes.Select,
            exact: true,
            nullable: true,
            data: {
                source: 'operators',
                key: 'Id',
                title: ['Name']
            }
        },
        {
            name: 'RegisterDate',
            title: 'Register Date',
            type: InputTypes.DateTime,
            nullable: true,
            between: true,
            value: {
                start: DateHelpers.startOfWeek(),
                end: DateHelpers.tonight()
            }
        },
        {
            name: 'Type',
            title: 'Transaction Type',
            type: InputTypes.Select,
            values: TransactionTypes,
            nullable: true,
        },
        {
            name: 'PlayerId',
            type: InputTypes.Number
        },
        {
            name: 'Username',
            type: InputTypes.Text
        },
        {
            name: 'Code'
        },
        {
            name: 'RetailId',
            title: 'Retail Id',
            nullable: true,
            type: InputTypes.Number
        },
        {
            name: 'RetailCode',
            title: 'Retail Code'
        },
        {
            name: 'Group',
            title: 'Player Group',
            type: InputTypes.Select,
            exact: true,
            nullable: true,
            data: {
                source: 'myPlayerGroups',
                key: 'Id',
                title: ['Name']
            }
        },
        CurrencyFilterField,
        {
            name: 'Retails',
            title: 'Only Retails',
            type: InputTypes.Bool
        }
    ]
};

export const resultModel = {
    fields: [
        {
            name: 'TransactionDate',
            title: 'Transaction Date',
            type: InputTypes.DateTime
        },
        {
            name: 'Id',
            title: 'Player Id'
        },
        {
            name: 'Username',
            type: InputTypes.Text,
            render: (val, row) => {
                return <a style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => {
                    gotoProfile(row.Id);
                }}>{val}</a>;
            }
        },
        {
            name: 'Code'
        },
        {
            name: 'Retail'
        },
        {
            name: 'RetailCode',
            title: 'Retail Code'
        },
        {
            name: 'Group'
        },
        {
            name: 'Admin'
        },
        {
            name: 'Currency'
        },
        {
            name: 'Amount',
            title: 'Amount',
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
        },
        {
            name: 'Balance Before',
            title: 'BalanceBefore',
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
        },
        {
            name: 'BalanceAfter',
            title: 'Balance After',
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
        },
        {
            name: 'CurrentBalance',
            title: 'Current Balance',
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
        },
        {
            name: 'Notes'
        }
    ]
};

export const ReportFirstTransactions = () => {
    return <FluxSearch
        model={searchModel}
        endpoint={ReportEndpoints.TransactionsFirstTransactionReport}
        resultModel={resultModel}
    />;
};
