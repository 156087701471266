/* eslint-disable react/display-name */
import './_risk-market-limits.scss';

import React, { useEffect, useState } from 'react';
import { API } from '../../../../../../lib/API/Api';
import { CMSEndpoints, RiskLimitEndpoints, SportEndpoints } from '../../../../../../lib/API/Endpoints';
import { convertLSportIdToBetradarSportId } from '../../../../../../lib/Helpers/LsportConversationTools';
import { lang } from '../../../../../../lib/Localization/language';
import { lsMarketGroups } from '../../../../../../pages/CMS/Sports/MarketGroups/SetupMarketGroups';
import { Button } from '../../../../../button/Button';
import { FluxCard } from '../../../../../card/FluxCard';
import { FluxInput } from '../../../../../form/Components/FluxInput';
import { InputTypes } from '../../../../../form/InputTypes';
import { FluxNotice } from '../../../../../notification/FluxNotice';
import { NotificationType } from '../../../../../notification/FluxNotification';
import { Popper } from '../../../../../notification/FluxPopper';
import { Table } from '../../../../../table/Table';
import { useSelector } from 'react-redux';


export const RiskMarketLimits = () => {
    const user = useSelector((state) => state.user);
    const [categories, setCategories] = useState(null);
    const [category, selectCategory] = useState(null);
    const [markets, setMarkets] = useState([]);

    const [marketGroups, setMarketGroups] = useState({ ...lsMarketGroups });

    useEffect(() => {
        API.post(SportEndpoints.Categories, {}, 'please wait loading categories', 'unable to load categories').then((result) => {
            setCategories(result.result.sort((a, b) => a.weight - b.weight));
        });

    }, []);

    useEffect(() => {
        if (!categories) return;

        API.post(CMSEndpoints.GetSportMarketGroups, {}).then((marketGroupsResult) => {
            var converted = {};
            try {
                converted = JSON.parse(marketGroupsResult.result);
            } catch (err) {
                converted = { ...lsMarketGroups };
            }
            if (!converted) {
                converted = { ...lsMarketGroups };
            }

            setMarketGroups(converted);
            API.post(`${CMSEndpoints.GetSportMarkets}/${window.user.features.feedType}`, {}).then((sportMarketsResult) => {

                API.post(RiskLimitEndpoints.GetMarketLimits(user.loginInformation.UserPrivileges.PointOfView)).then((stakeResults) => {
                    var csv = sportMarketsResult.result;
                    var data = csv.split('\n');
                    var converted = data.map(x => {
                        var p = x.split(',');
                        const sport = p[0];
                        const id = p[2];
                        var stakeFactor = null;
                        if (stakeResults.result) {
                            const stakeKey = Object.keys(stakeResults.result).find(x => x == `${sport}:${id}`);
                            if (stakeKey) {
                                stakeFactor = stakeResults.result[stakeKey];
                            }
                        }
                        return {
                            sport: sport,
                            sportName: p[1],
                            id: id,
                            name: p[3],
                            selections: p[4],
                            inPlaySettlements: p[9] == 'True',
                            stakeFactor: stakeFactor
                        };
                    });
                    converted = converted.filter(x => x != null);
                    setMarkets(converted);
                });
            });
        });
    }, [categories]);

    const save = () => {
        const data = markets.map(x => { return { id: x.id, sport: x.sport, stakeFactor: x.stakeFactor }; });
        API.post(RiskLimitEndpoints.SaveMarketLimits(user.loginInformation.UserPrivileges.PointOfView), data, 'please wait', 'unable to save market limits!').then(() => {
            Popper.pop('information', 'Market limits saved!');
        });
    };

    const roundToHundredth = (value) => {
        if (isNaN(value)) return null;
        if (value >= 0.001 && value <= 0.009) {
            return 0.01;
        }
        return Math.round(value * 100) / 100;
    };

    const context = {
        update: (e, row) => {
            var copy = markets;
            var field = copy.find(x => x.id == row.toString() && x.sport == category.id.toString());
            if (!field) return;
            var value = e.target.value;
            if (e.target.value == field[e.target.name]) return;
            if (e.target.type == 'number') {
                value = roundToHundredth(parseFloat(value));
                e.target.value = value;
            }
            field[e.target.name] = value;
            setMarkets(copy);

        }
    };

    const renderGroup = () => {
        if (!marketGroups) return;
        if (!category) return;
        var convertedId = convertLSportIdToBetradarSportId(category.id);
        if (!marketGroups[convertedId]) return;
        var sportMarkets = marketGroups[convertedId];
        var data = [];

        sportMarkets.map(x => {
            if (x.markets && x.markets.length > 0) {
                data = data.concat(x.markets);
            }
        });

        var marketList = markets.filter(x => {
            return x.sport.toString() == category.id.toString();
        });

        if (data) {
            var groupModel = {
                fields: [
                    {
                        name: 'id',
                        title: 'Id',
                        render: (val, row) => {
                            return row;
                        }
                    },
                    {
                        name: 'marketName',
                        title: 'Name',
                        render: (val, row) => {
                            const market = marketList.find(x => x.id == row);
                            return <div>{market?.name}</div>;
                        }
                    },
                    {
                        name: 'selections',
                        title: 'Selections',
                        render: (val, row) => {
                            const market = marketList.find(x => x.id == row);
                            return <div>{market?.selections}</div>;
                        }
                    },
                    {
                        name: 'stakeFactor',
                        title: 'Stake Limit',
                        render: (val, row, field, context) => {
                            const fieldData = markets.find(x => x.id == row && x.sport == category.id.toString());
                            return <flex key={row + '_' + category.id} className="gap-5">
                                <input type='number'
                                    placeholder='Empty, 0, 100 => Disabled'
                                    name={field.name}
                                    min={0}
                                    step={0.01}
                                    defaultValue={fieldData?.[field.name]}
                                    onChange={(e) => context.update(e, row)} />
                                <span>% Limit</span>
                            </flex>;
                        }
                    }
                ]
            };

            var copy = {};
            data.map(x => {
                copy[x] = x;
            });

            data = Object.keys(copy);


            return <flex className='gap-20 vertical'>
                <Table
                    className={'fx-borders fx-shadow'}
                    model={groupModel}
                    context={context}
                    key={data}
                    data={data}
                />
            </flex>;
        }

    };


    if (!categories) return <div></div>;

    return <flex className='vertical gap-10 risk-market-limits'>
        <FluxCard
            className='scroll-enable'
            title={<flex className='gap-10 fit'>
                <span className='bold title'>{lang('Setup Overview Markets')}</span>
                <flex className='center sport-selector'>
                    <FluxInput
                        field={{
                            name: 'Sport',
                            title: 'Sport',
                            type: InputTypes.Select,
                            required: false,
                            nullable: true,
                            data: {
                                source: SportEndpoints.Categories,
                                parameters: {},
                                parser: (result) => {
                                    var results = [];
                                    result.result.sort((a, b) => a.weight - b.weight).filter(x => x.enabled).map(x => {
                                        results.push({ title: `${x.id} - ${x.name}`, value: x.id });
                                    });
                                    return results;
                                }
                            }
                        }}
                        onChange={(field, value) => {
                            selectCategory(categories.find(x => x.id == value));
                        }}
                        data={{}}
                    ></FluxInput>
                </flex>
                <flex className='align-right'>
                    < Button className='success align-right' title='Save' onClick={() => save()} />
                </flex>
            </flex>
            }>
            {!category && < FluxNotice type={NotificationType.Information}
                title={'Choose Sport from above'}
                description='Please choose a sport from above to continue' />
            }
            {category && renderGroup()}
        </FluxCard>
    </flex >;
};
