import './_trader-tools-page.scss';

import React, { useEffect, useState } from 'react';
import { FluxTab } from '../../tab/FluxTab';
import { ActionButton, FluxCard } from '../../card/FluxCard';
import { lang } from '../../../lib/Localization/language';
import { API } from '../../../../v2/Lib/Api/Api';
import ReactSelect from 'react-select';
import { Button } from '../../button/Button';
import { DateTimeInput } from '../../form/Components/DateTimeInput';
import { InputTypes } from '../../form/InputTypes';
import { DateHelpers } from '../../../lib/Helpers/DateHelpers';
import { Table } from '../../table/Table';
import { EventStatus } from '../../../lib/Enums/BettingEnums';
import { TraderEndpoints, SportEndpoints } from '../../../lib/API/Endpoints';
import { appHistory } from '../../../..';


const resultModel = {
    fields: [
        {
            name: 'date',
            title: 'Event Date',
            type: InputTypes.DateTime
        },
        {
            name: 'id',
            title: 'Event Id'
        },
        {
            name: 'sport',
            title: 'Sport'
        },
        {
            name: 'region',
            title: 'Region'
        },
        {
            name: 'league',
            title: 'League'
        },
        {
            name: 'eventName',
            title: 'Event',
            render: (val, row) => {
                return row.name;
            }
        },
        {
            name: 'status',
            title: 'Status',
            type: InputTypes.Select,
            values: EventStatus
        },
        {
            name: 'totalTickets',
            title: 'Total Tickets'
        },
        {
            name: 'subscribed',
            title: 'Subscribed',
            type: InputTypes.Bool
        }
    ]
};


const MarketView = (props) => {
    const { event, withBets } = { ...props };

    const [markets, setMarkets] = useState(null);

    useEffect(() => {
        API.get(withBets ? TraderEndpoints.EventSelectionsWithBets(event.id) : TraderEndpoints.EventSelections(event.id)).then((result) => {
            let marketsDictionary = {};

            result.result.map(x => {
                if (!marketsDictionary[x.marketId]) {
                    marketsDictionary[x.marketId] = {
                        id: x.marketId,
                        name: x.marketName,
                        selections: []
                    }
                }
                marketsDictionary[x.marketId].selections.push(x);
            });

            setMarkets(marketsDictionary);
        });
    }, []);

    const win = (row) => {
        let disabled = true;
        if (disabled) return;
        API.post(TraderEndpoints.SendSettlement(operatorId, event.id), prepareData(row, 2)).then(() => {
            setData(null);
            load();
        });
    };

    const lost = (row) => {
        let disabled = true;
        if (disabled) return;

        API.post(TraderEndpoints.SendSettlement(operatorId, event.id), prepareData(row, 1)).then(() => {
            setData(null);
            load();
        });
    };

    const refund = (row) => {
        let disabled = true;
        if (disabled) return;

        API.post(TraderEndpoints.SendSettlement(operatorId, event.id), prepareData(row, 3)).then(() => {
            setData(null);
            load();
        });
    };

    const halfLost = (row) => {
        let disabled = true;
        if (disabled) return;

        API.post(TraderEndpoints.SendSettlement(operatorId, event.id), prepareData(row, 4)).then(() => {
            setData(null);
            load();
        });
    };

    const halfWin = (row) => {
        let disabled = true;
        if (disabled) return;

        API.post(TraderEndpoints.SendSettlement(operatorId, event.id), prepareData(row, 5)).then(() => {
            setData(null);
            load();
        });
    };

    const renderMarket = (m) => {
        let marketData = markets[m];
        return <FluxCard title={marketData.name}>
            <Table
                className={'fx-borders fx-shadow'}
                model={{
                    fields: [
                        {
                            name: 'selectionId',
                            title: 'SelectionId'
                        },
                        {
                            name: 'selectionName',
                            title: 'Name'
                        },
                        {
                            name: 'baseLine',
                            title: 'Base Line'
                        },
                        {
                            name: 'line',
                            title: 'line'
                        },
                        {
                            name: 'price',
                            title: 'Price'
                        },
                        {
                            name: 'totalTickets',
                            title: 'Total Tickets'
                        },
                        {
                            name: 'status',
                            title: 'Status',
                            render: (val) => {
                                switch (val) {
                                    case 1:
                                        return <span>{lang('Open')}</span>
                                    case 2:
                                        return <span>{lang('Suspended')}</span>
                                    case 3:
                                        return <span>{lang('Settled')}</span>
                                    default:
                                        return val;
                                }
                            }
                        },
                        {
                            name: 'settlement',
                            title: 'Settlement',
                            render: (val) => {
                                switch (val) {
                                    case -1:
                                        return <span>{lang('Cancelled')}</span>
                                    case 0:
                                        return <span>{lang('Open')}</span>
                                    case 1:
                                        return <span>{lang('Loser')}</span>
                                    case 2:
                                        return <span>{lang('Winner')}</span>
                                    case 3:
                                        return <span>{lang('Refund')}</span>
                                    case 4:
                                        return <span>{lang('Half Lost')}</span>
                                    case 5:
                                        return <span>{lang('Half Win')}</span>
                                    default:
                                        return val;
                                }
                            }
                        }
                    ]
                }} data={marketData.selections}
                buttons={(row) => {
                    return <flex className='gap-5'>
                        <Button title='WIN' className='success' onClick={() => win(row)} />
                        <Button title='LOST' className='warning' onClick={() => lost(row)} />
                        <Button title='REFUND' className='' onClick={() => refund(row)} />
                        <Button title='HALF LOST' className='success' onClick={() => halfLost(row)} />
                        <Button title='HALF WIN' className='success' onClick={() => halfWin(row)} />
                    </flex>;
                }}
                onRowClick={(row, element, index) => {
                    console.log(index);
                }}
            />
        </FluxCard>;
    };

    if (!markets) return <></>;
    return <flex className='vertical gap-10'>
        {Object.keys(markets).map(x => {
            return renderMarket(x);
        })}
    </flex>;
};

const MarketViews = {
    Selections: 'Selections',
    SelectionWithBets: 'SelectionWithBets'
};

const EventView = (props) => {
    const { event, onClose } = { ...props };

    const [eventData, setEvent] = useState(event);

    const [view, setView] = useState(MarketViews.Selections);

    let tabButtons = [
        {
            title: 'All Markets'
        },
        {
            title: 'Markets with bets'
        }
    ];

    useEffect(() => {
        if (event.empty) {
            API.get(TraderEndpoints.GetEventById(event.id)).then((result) => {
                setEvent(result.result);
            });
        }
    }, []);


    return <flex className='vertical gap-10'>
        <FluxCard title={eventData.name} buttons={<flex className='align-right'>
            <ActionButton title='Close' icon='times' onClick={() => {
                onClose();
            }} /></flex>}>
        </FluxCard>
        <FluxTab buttons={tabButtons} className='gap-10 fx-shadow fx-radius rounded-buttons overflow-hidden' onChange={(index) => {
            console.log(index);
            switch (index) {
                case 0:
                    setView(MarketViews.Selections);
                    break;
                case 1:
                    setView(MarketViews.SelectionWithBets);
                    break;
            }
        }}>
            {view == MarketViews.Selections && <MarketView event={eventData} />}
            {view == MarketViews.SelectionWithBets && <MarketView event={eventData} withBets />}
        </FluxTab>
    </flex>
};

export const TraderToolsPage = () => {
    const [sports, setSports] = useState(null);
    const [regions, setRegions] = useState(null);
    const [leagues, setLeagues] = useState(null);
    const [sport, selectSport] = useState(null);
    const [region, selectRegion] = useState(null);
    const [league, selectLeague] = useState(null);

    const [data, setData] = useState({ startDate: DateHelpers.startOfWeek(), endDate: DateHelpers.tonight() });
    const [events, setEvents] = useState(null);
    const [event, selectEvent] = useState(undefined);

    let tabButtons = [
        {
            title: 'Event Browser'
        },
        {
            title: 'Upcoming Events'
        },
        {
            title: 'Live Events'
        },
        {
            title: 'Top Events'
        },
        {
            title: 'Top Leagues'
        }
    ];

    useEffect(() => {
        API.post(SportEndpoints.Categories, null).then(result => {
            setSports(result.result.sort((a, b) => a.name > b.name ? 1 : -1));
            const urlParams = new URLSearchParams(window.location.search);
            const e = urlParams.get('e'); // retail login
            if (!isNaN(parseFloat(e))) {
                selectEvent({ id: e, empty: true });
            }

        });
    }, []);

    useEffect(() => {
        if (event === undefined) return;
        if (!event) {
            appHistory.push(`${window.location.pathname}`);
            return;
        }

        appHistory.push(`${window.location.pathname}?&e=${event.id}`);

    }, [event]);

    useEffect(() => {
        if (!sports) return;
        if (!sport) {
            selectSport(sports.find(x => x.id == 6046));
        }
    }, [sports]);

    useEffect(() => {
        if (!regions) return;
    }, [regions]);

    useEffect(() => {
        if (!sport) return;
        updateForm('sportId', sport.id);
        API.post(`${SportEndpoints.Regions}/${sport.id}`).then((result) => {
            setRegions(result.result);
        });
    }, [sport]);

    useEffect(() => {
        if (!region) return;
        updateForm('regionId', region.id);
        API.post(`${SportEndpoints.Leagues}/${sport.id}/${region.id}`).then((result) => {
            setLeagues(result.result);
        });
    }, [region]);

    useEffect(() => {
        if (!league) return;
        updateForm('leagueId', league.id);
    }, [league]);



    const renderSports = () => {
        let selectedValue = null;
        let options = sports?.map(x => {
            return {
                value: x.id,
                label: x.name
            };
        });

        selectedValue = sport ? options.find(x => x.value == sport.id) : null;

        return <flex className='vertical gap-5 input-title'>
            <span>{lang('Sports')}</span>
            <ReactSelect
                className={`react-select ${sports ? '' : 'disabled'}`}
                styles={{ menuPortal: base => ({ ...base, zIndex: 100 }) }}
                menuPortalTarget={document.body}
                defaultValue={selectedValue}
                options={options}
                key={selectedValue}
                onChange={(e) => {
                    if (!e) {
                        selectSport(null);
                        return;
                    }
                    setRegions(null);
                    selectSport(sports.find(x => x.id == e.value));
                }}
                name={'Sports'}

                hideSelectedOptions={false}
                closeMenuOnSelect={true}
                isClearable={true}
                isSearchable={true}
            />
        </flex>;
    };

    const renderRegions = () => {
        let selectedValue = null;
        let options = regions?.map(x => {
            return {
                value: x.id,
                label: x.name
            };
        });

        return <flex className='vertical gap-5 input-title'>
            <span>{lang('Regions')}</span>
            <ReactSelect
                className={`react-select ${regions ? '' : 'disabled'}`}
                styles={{ menuPortal: base => ({ ...base, zIndex: 100 }) }}
                menuPortalTarget={document.body}
                defaultValue={selectedValue}
                options={options}
                onChange={(e) => {
                    if (!e) {
                        selectRegion(null);
                        return;
                    }
                    selectRegion(regions.find(x => x.id == e.value));
                }}
                name={'Regions'}
                hideSelectedOptions={false}
                closeMenuOnSelect={true}
                isClearable={true}
                isSearchable={true}
            />
        </flex>;
    };

    const renderLeagues = () => {
        let selectedValue = null;
        let options = leagues?.map(x => {
            return {
                value: x.id,
                label: x.name
            };
        });

        return <flex className='vertical gap-5 input-title'>
            <span>{lang('Leagues')}</span>
            <ReactSelect
                className={`react-select ${leagues ? '' : 'disabled'}`}
                styles={{ menuPortal: base => ({ ...base, zIndex: 100 }) }}
                menuPortalTarget={document.body}
                defaultValue={selectedValue}
                options={options}
                onChange={(e) => {
                    if (!e) {
                        selectLeague(null);
                        return;
                    }
                    selectLeague(leagues.find(x => x.id == e.value));
                }}
                name={'Leagues'}
                hideSelectedOptions={false}
                closeMenuOnSelect={true}
                isClearable={true}
                isSearchable={true}
            />
        </flex>;
    };

    const searchEvents = () => {
        if (!data.sportId) return;

        API.post(TraderEndpoints.BrowseEvents, data).then((result) => {
            setEvents(result.result);
        });
    };

    const updateForm = (name, value) => {
        let copy = { ...data };
        copy[name] = value;
        setData(copy);
    };

    if (event) {
        return <EventView event={event} onClose={() => {
            selectEvent(null);
        }} />;
    }
    return <flex className='trader-tools-page vertical gap-20 overflow-hidden'>
        <FluxTab buttons={tabButtons} className='gap-10 fx-shadow fx-radius rounded-buttons overflow-hidden'>
            <flex className='vertical gap-10 overflow-hidden'>
                <FluxCard title='Search Events'>
                    <flex className='vertical gap-10'>
                        <flex className='gap-10'>
                            {renderSports()}
                            {renderRegions()}
                            {renderLeagues()}
                            <flex className='vertical gap-5 input-title'>
                                <span>{lang('Status')}</span>
                                <ReactSelect
                                    className={'react-select'}
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 100 }) }}
                                    menuPortalTarget={document.body}
                                    options={Object.keys(EventStatus).map(x => {
                                        return {
                                            label: EventStatus[x],
                                            value: x
                                        };
                                    })}
                                    onChange={(e) => {
                                        if (!e) {
                                            updateForm('status', null);
                                            return;
                                        }

                                        updateForm('status', e.map(x => x.value).join(','));
                                    }}
                                    isMulti={true}
                                    name={'Status'}
                                    hideSelectedOptions={false}
                                    closeMenuOnSelect={true}
                                    isClearable={true}
                                    isSearchable={true}
                                />
                            </flex>
                            <flex className='vertical gap-5 input-title'>
                                <span>{lang('Events')}</span>
                                <ReactSelect
                                    className={'react-select'}
                                    styles={{ menuPortal: base => ({ ...base, zIndex: 100 }) }}
                                    menuPortalTarget={document.body}
                                    defaultValue={{
                                        label: 'All',
                                        value: false
                                    }}
                                    options={[
                                        {
                                            label: 'All',
                                            value: false
                                        },
                                        {
                                            label: 'With tickets only',
                                            value: true
                                        }
                                    ]}
                                    onChange={(e) => {
                                        updateForm('eventsWithTickets', e.value);
                                    }}
                                    name={'EventsWithTickets'}
                                    hideSelectedOptions={false}
                                    closeMenuOnSelect={true}
                                    isClearable={false}
                                    isSearchable={false}
                                />
                            </flex>
                            <flex className='vertical gap-5 input-title'>
                                <span>{lang('Start Date')}</span>
                                <DateTimeInput field={{ name: 'StartDate', value: data.startDate, type: InputTypes.DateTime }} data={{}} onChange={() => {

                                }} />
                            </flex>
                            <flex className='vertical gap-5 input-title'>
                                <span>{lang('End Date')}</span>
                                <DateTimeInput field={{ name: 'EndDate', value: data.endDate, type: InputTypes.DateTime }} data={{}} onChange={() => {

                                }} />
                            </flex>
                        </flex>
                        <hr />
                        <flex className='gap-10'>
                            <flex className='vertical gap-5 input-title'>
                                <span>{lang('Search Event By Name')}</span>
                                <input onChange={(e) => updateForm('name', e.target.value)} />
                            </flex>
                            <flex className='vertical gap-5 input-title'>
                                <span>{lang('Search Event By Id')}</span>
                                <input />
                            </flex>
                        </flex>
                        <flex>
                            <Button title='Search' className={'success'} onClick={() => {
                                searchEvents();
                            }} />
                        </flex>
                    </flex>
                </FluxCard>
                <FluxCard className='overflow-hidden trader-tool-events'>
                    <div className='events-holder'>
                        <Table
                            className={'fx-borders fx-shadow'}
                            model={resultModel} data={events ?? []}
                            buttons={(row) => {
                                return <flex className='gap-5'>
                                    <Button title='View Event' className={'success'} onClick={() => {
                                        selectEvent(row);
                                    }} />
                                    {row.subscribed && <Button title='Unsubscribe' className={'warning'} />}
                                    {!row.subscribed && <Button title='Subscribe' className={'success'} />}
                                </flex>;
                            }}
                            onRowClick={(row, element, index) => {
                                console.log(index);
                            }}
                        />
                    </div>
                </FluxCard>
            </flex>
        </FluxTab>
    </flex>;
};