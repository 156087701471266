/* eslint-disable react/display-name */
import React from 'react';
import { ReportEndpoints } from '../../../../v2/Lib/Api/Api';
import { InputTypes } from '../../../components/form/InputTypes';
import { FluxSearch } from '../../../components/search/FluxSearch';
import { DateHelpers } from '../../../lib/Helpers/DateHelpers';
import { footerCurrencyValues } from '../../../lib/Helpers/Reports/Footer';
import { Currency } from '../../../components/currency/Currency';
import { useSelector } from 'react-redux';
import { CurrencyFilterField } from '../../../lib/Models/CurrencyModel';


export const searchModel = {
    fields: [
        {
            name: 'OperatorId',
            title: 'Operator',
            type: InputTypes.Select,
            data: {
                source: 'operators',
                key: 'Id',
                title: ['Name']
            }
        },
        {
            name: 'EventTime',
            title: 'Event Time',
            type: InputTypes.DateTime,
            nullable: true,
            between: true,
            value: {
                start: DateHelpers.startOfWeek(),
                end: DateHelpers.tonight()
            }
        },
        {
            name: 'RetailId',
            title: 'Retail Id',
            nullable: true,
            type: InputTypes.Number
        },
        {
            name: 'RetailCode',
            title: 'Retail Code'
        },
        {
            name: 'Group',
            title: 'Player Group',
            type: InputTypes.Select,
            nullable: true,
            data: {
                source: 'myPlayerGroups',
                key: 'Id',
                title: ['Name']
            }
        },
        CurrencyFilterField
    ]
};

export const resultModel = {
    fields: [
        {
            name: 'CasinoHand',
            title: 'Live Casino Games'
        },
        {
            name: 'CasinoHandCancel',
            title: 'Live Casino Games Cancelled'
        },
        {
            name: 'CasinoBet',
            title: 'Live Casino Bet',
            render: (val, row) => {
                return <Currency amount={val} sign={row.Currency} />;
            },
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        },
        {
            name: 'CasinoBetCancel',
            title: 'Live Casino Bet Cancel',
            render: (val, row) => {
                return <Currency amount={val} sign={row.Currency} />;
            },
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        },
        {
            name: 'CasinoWin',
            title: 'Live Casino Win',
            render: (val, row) => {
                return <Currency amount={val} sign={row.Currency} />;
            },
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        },
        {
            name: 'SlotHand',
            title: 'Slot Games'
        },
        {
            name: 'SlotHandCancel',
            title: 'Slot Games Cancelled'
        },
        {
            name: 'SlotBet',
            title: 'Slot Bet',
            render: (val, row) => {
                return <Currency amount={val} sign={row.Currency} />;
            },
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        },
        {
            name: 'SlotBetCancel',
            title: 'Slot Bet Cancel',
            render: (val, row) => {
                return <Currency amount={val} sign={row.Currency} />;
            },
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        },
        {
            name: 'SlotWin',
            title: 'Slot Win',
            render: (val, row) => {
                return <Currency amount={val} sign={row.Currency} />;
            },
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        },
        {
            name: 'GGR',
            title: 'GGR',
            render: (val, row) => {
                return <Currency amount={val} sign={row.Currency} />;
            },
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        }
    ]
};


export const ReportCasinoRevenues = () => {
    const user = useSelector((state) => state.user.loginInformation);
    searchModel.fields.find(x => x.name == 'OperatorId').value = user.UserPrivileges.PointOfView;

    return <FluxSearch
        model={searchModel}
        endpoint={ReportEndpoints.CasinoRevenuesReport}
        resultModel={resultModel}
        onPrepareResultModel={(model) => {
            var copy = { ...model };

            return copy;
        }}
    />;
};
