import React, { useEffect, useState } from 'react';
import { Button } from '../../../components/button/Button';
import { FluxCard } from '../../../components/card/FluxCard';
import { Icon } from '../../../components/icon/Icon';
import { Popper } from '../../../components/notification/FluxPopper';
import { SportsTree } from '../../../components/sports/SportsTree/SportsTree';
import { Table } from '../../../components/table/Table';
import { API } from '../../../lib/API/Api';
import { CMSEndpoints, DataEndpoints } from '../../../lib/API/Endpoints';
import { lang } from '../../../lib/Localization/language';
import './_languages.scss';

const CMSKeys = {
    'website': 'Web',
    'sports': 'Sports',
    'regions': 'Regions',
    'leagues': 'Leagues',
    'teams': 'Teams',
    'markets': 'Markets',
    'selections': 'Selections',
    'marketGroups': 'Market Groups',
    'overviewMarkets': 'Overview Markets',
    'providers': 'Providers'
};

const defaultFocus = (e) => {
    e.target.setAttribute('data-value', e.target.value);
};

export const LanguageConfigurator = () => {
    const [data, setData] = useState({ website: {}, marketGroups: {}, overviewMarkets: {}, captions: {}, copy: {} });
    const [filter, setFilter] = useState('a');
    const [regionfilter, setRegionFilter] = useState(0);
    const [leaguefilter, setLeagueFilter] = useState(0);
    const [edit, setEdit] = useState(null);
    const [search, setSearch] = useState(null);
    const [languages, setAvailableLanguages] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [saving, setSaving] = useState(false);
    const [selectedKeys, setSelectedKeys] = useState({});
    const [targetKeys, setTargetKeys] = useState('website');

    const [searchFilter, setSearchFilter] = useState(null);
    const [minimized, minimizeMenu] = useState(false);
    const searchRef = React.createRef();

    useEffect(() => {
        API.post(CMSEndpoints.GetProviderLanguages, {}).then((providersResult) => {
            var providers = {};
            providersResult.result.map(x => {
                providers[x.display] = {};
            })
            API.post(`${CMSEndpoints.GetSportCategoriesLanguages}/${window.user.features.feedType}`, {}).then((result) => {
                var categories = {};

                (result.result ? JSON.parse(result.result) : []).map(x => {
                    categories[x.name] = x.languages;
                });


                API.post(`${CMSEndpoints.GetSportRegionsLanguages}/${window.user.features.feedType}`, {}).then((result) => {
                    var regions = {};

                    (result.result ? JSON.parse(result.result) : []).map(x => {
                        regions[x.name] = x.languages;
                    });

                    API.post(`${CMSEndpoints.GetSportMarketLanguages}/${window.user.features.feedType}`, {}).then((result) => {
                        var markets = {};

                        (result.result ? JSON.parse(result.result) : []).map(x => {
                            markets[x.Name] = x.Languages;
                        });


                        API.post(`${CMSEndpoints.GetSportSelectionLanguages}/${window.user.features.feedType}`, {}).then((result) => {
                            var selectionsRaw = result.result ? JSON.parse(result.result) : {};
                            var selections = {};
                            Object.keys(selectionsRaw).map(x => {
                                selections[x] = selectionsRaw[x].Languages;
                            });


                            selections['Player 1'] = {
                                Tr: '1. Oyuncu',
                                Es: 'Jugador 1'
                            };

                            selections['Player 2'] = {
                                Tr: '2. Oyuncu',
                                Es: 'Jugador 2'
                            };

                            selections['Home Team'] = {
                                Tr: 'Ev sahibi',
                                Es: 'Equipo Local'
                            };

                            selections['Away Team'] = {
                                Tr: 'Rakip takım',
                                Es: 'Equipo Visitante'
                            };

                            API.post(`${CMSEndpoints.GetDefaultLanguage}`).then((result) => {
                                var converted = JSON.parse(result.result);
                                loadKeys(converted, providers, categories, regions, markets, selections);
                            });
                        });
                    });
                });
            });
        });

    }, []);

    useEffect(() => {
        if (!targetKeys) return;
        if (!data) return;
        if (!data.captions[targetKeys]) return;
        setFilter(data.captions[targetKeys][0]);
        setRegionFilter(0);
        setLeagueFilter(0);
        setSearchFilter(null);
    }, [targetKeys]);

    const loadKeys = (defaultLanguageKeys, providers, defaultSportNames, defaultRegionNames, defaultMarketNames, defaultSelectionNames) => {
        API.post(`${CMSEndpoints.Contents}/true`, { type: 'language' }, 'please wait', 'unable to fetch language').then((result) => {

            // Website is the saved language for the website
            var website = JSON.parse(result.result.find(x => x.key == 'Content')?.content ?? '{}');

            //  Sport categories
            var sports = JSON.parse(result.result.find(x => x.key == 'Sports')?.content ?? '{}');

            //  Regions 
            var regions = JSON.parse(result.result.find(x => x.key == 'Regions')?.content ?? '{}');

            // Leagues
            var leagues = JSON.parse(result.result.find(x => x.key == 'Leagues')?.content ?? '{}');

            // Teams
            var teams = JSON.parse(result.result.find(x => x.key == 'Teams')?.content ?? '{}');

            //  Markets are the sport markets
            var markets = JSON.parse(result.result.find(x => x.key == 'Markets')?.content ?? '{}');

            //  Selections of sport markets
            var selections = JSON.parse(result.result.find(x => x.key == 'Selections')?.content ?? '{}');

            // Market Groups are saved from sport setup
            var marketGroups = JSON.parse(result.result.find(x => x.key == 'MarketGroups')?.content ?? '{}');

            // Overview Markets are saved from sport setup
            var overviewMarkets = JSON.parse(result.result.find(x => x.key == 'OverviewMarkets')?.content ?? '{}');


            var defaultLanguage = result.result.find(x => x.key == 'DefaultLanguage').content;
            var version = result.result.find(x => x.key == 'Version').content;


            API.post(DataEndpoints.AvailableLanguages, {}).then((result) => {
                var availableLanguages = result.result;
                var languageKeys = {};
                availableLanguages.map(x => {
                    languageKeys[x.key] = true;
                });

                setAvailableLanguages(availableLanguages);

                setData({
                    website: prepareKeys(website, defaultLanguageKeys, languageKeys),
                    marketGroups: prepareKeys(marketGroups, marketGroups, languageKeys, 'marketGroups'),
                    sports: prepareKeys(sports, defaultSportNames, languageKeys),
                    regions: prepareKeys(regions, defaultRegionNames, languageKeys),
                    markets: prepareKeys(markets, defaultMarketNames, languageKeys),
                    leagues: prepareKeys(leagues, {}, languageKeys),
                    teams: prepareKeys(teams, {}, languageKeys),
                    selections: prepareKeys(selections, defaultSelectionNames, languageKeys),
                    overviewMarkets: prepareKeys(overviewMarkets, overviewMarkets, languageKeys),
                    providers: prepareKeys(providers, {}, languageKeys),
                    captions: {
                        sports: prepareCaptions(defaultSportNames),
                        regions: prepareCaptions(defaultRegionNames),
                        leagues: prepareCaptions(leagues, true),
                        teams: prepareCaptions(teams, true),
                        markets: prepareCaptions(defaultMarketNames),
                        selections: prepareCaptions(defaultSelectionNames),
                        website: prepareCaptions(defaultLanguageKeys),
                        marketGroups: prepareCaptions(marketGroups),
                        overviewMarkets: prepareCaptions(overviewMarkets),
                        providers: prepareCaptions(providers),
                    },
                    copy: structuredClone(website),
                    version: version,
                    defaultLanguage: defaultLanguage
                });
                setLoaded(true);
            });
        });
    };

    const prepareKeys = (source, defaults, languages, targetKey) => {
        let missingKeys = Object.keys(defaults).filter(key => !(key in source));

        Object.keys(defaults).map(key => {
            if (!source[key]) {
                source[key] = defaults[key];
            }
            source[key]['id'] = key;

            Object.keys(defaults[key]).map(l => {
                if (!languages[l] && targetKey != 'marketGroups') {
                    delete source[key][l];
                } else {
                    if (!source[key][l] || source[key][l] == '') {
                        source[key][l] = defaults[key][l] ?? defaults[key].En;
                    }
                    if (!source[key]['id'] || source[key]['id'] == '') {
                        source[key]['id'] = key;
                    }
                }
            });
        });

        missingKeys.forEach(key => {
            source[key]['id'] = key;
        });
        return source;
    };

    const prepareCaptions = (defaultLanguageKeys, metaId) => {
        var captions = {};
        Object.keys(defaultLanguageKeys).map(x => {
            if (metaId) {
                captions[x.toLocaleLowerCase().split('|')[1][0]] = 0;
            } else {
                captions[x[0].toLocaleLowerCase()] = 0;
            }
        });
        captions = Object.keys(captions).sort((a, b) => a < b ? -1 : 1);
        return captions;
    };

    const hasNotDefaultData = (data, langs) => {
        for (let lang of langs) {
            if (data.hasOwnProperty(lang)) {
                return true; // Object has at least one of the langs
            }
        }
        return false; // Object doesn't have any of the langs
    }
    const removeIdPropertyFromList = (data) => {
        var res = {};
        for (let key in data) {
            res[key] = { ...data[key] };
            if (data[key]?.hasOwnProperty("id")) {
                delete res[key]["id"];
            }
        }
        return res;
    }

    const save = () => {
        data.markets["Total Corners Away Team"] = data.markets["Total Corners - Away Team"];
        delete data.markets["Total Corners - Away Team"];
        data.markets["Total Corners Home Team"] = data.markets["Total Corners - Home Team"];
        delete data.markets["Total Corners - Home Team"];
        setSaving(true);

        API.post(CMSEndpoints.SaveContent, {
            Type: 'Language',
            Key: 'Content',
            Content: JSON.stringify(removeIdPropertyFromList(data.website))
        }, 'saving language', 'save failed').then(
            () => {

                API.post(CMSEndpoints.SaveContent, {
                    Type: 'Language',
                    Key: 'Sports',

                    Content: JSON.stringify(removeIdPropertyFromList(data.sports))
                }, 'saving language', 'save failed').then(
                    () => {
                        API.post(CMSEndpoints.SaveContent, {
                            Type: 'Language',
                            Key: 'Regions',

                            Content: JSON.stringify(removeIdPropertyFromList(data.regions))
                        }, 'saving language', 'save failed').then(
                            () => {
                                var leagues = data.leagues;
                                if (leagues) {
                                    var notDefaultOnes = {};
                                    var langs = languages.map(x => x.key).filter(x => x != 'En');
                                    if (langs.length > 0) {

                                        for (let key in leagues) {
                                            const element = leagues[key];
                                            if (!element?.id) {
                                                element.id = key;
                                            }
                                            var notDefault = hasNotDefaultData(element, langs);
                                            if (notDefault) {
                                                notDefaultOnes[element.id] = element;
                                            }
                                        }
                                        leagues = notDefaultOnes;
                                    }
                                }
                                API.post(CMSEndpoints.SaveContent, {
                                    Type: 'Language',
                                    Key: 'Leagues',

                                    Content: JSON.stringify(removeIdPropertyFromList(leagues))
                                }, 'saving language', 'save failed').then(
                                    () => {
                                        var teams = data.teams;
                                        if (teams) {
                                            var notDefaultOnes = {};
                                            var langs = languages.map(x => x.key).filter(x => x != 'En');
                                            if (langs.length > 0) {

                                                for (let key in teams) {
                                                    const element = teams[key];
                                                    if (!element?.id) {
                                                        element.id = key
                                                    }
                                                    var notDefault = hasNotDefaultData(element, langs);
                                                    if (notDefault) {
                                                        notDefaultOnes[element.id] = element;
                                                    }
                                                }
                                                teams = notDefaultOnes;
                                            }
                                        }
                                        API.post(CMSEndpoints.SaveContent, {
                                            Type: 'Language',
                                            Key: 'Teams',

                                            Content: JSON.stringify(removeIdPropertyFromList(teams))
                                        }, 'saving language', 'save failed').then(
                                            () => {
                                                API.post(CMSEndpoints.SaveContent, {
                                                    Type: 'Language',
                                                    Key: 'Markets',

                                                    Content: JSON.stringify(removeIdPropertyFromList(data.markets))
                                                }, 'saving language', 'save failed').then(
                                                    () => {
                                                        API.post(CMSEndpoints.SaveContent, {
                                                            Type: 'Language',
                                                            Key: 'Selections',

                                                            Content: JSON.stringify(removeIdPropertyFromList(data.selections))
                                                        }, 'saving language', 'save failed').then(
                                                            () => {
                                                                API.post(CMSEndpoints.SaveContent, {
                                                                    Type: 'Language',
                                                                    Key: 'MarketGroups',

                                                                    Content: JSON.stringify(removeIdPropertyFromList(data.marketGroups))
                                                                }, 'saving language', 'save failed').then(
                                                                    () => {
                                                                        API.post(CMSEndpoints.SaveContent, {
                                                                            Type: 'Language',
                                                                            Key: 'OverviewMarkets',

                                                                            Content: JSON.stringify(removeIdPropertyFromList(data.overviewMarkets))
                                                                        }, 'saving language', 'save failed').then(
                                                                            () => {
                                                                                Popper.pop('information', 'Language saved.');
                                                                                setSaving(false);
                                                                            }
                                                                        );
                                                                    }
                                                                );
                                                            }
                                                        );
                                                    }
                                                );
                                            }
                                        );
                                    }
                                );
                            }
                        );
                    }
                );
            }
        );
    };

    const download = () => {
        data.markets["Total Corners Away Team"] = data.markets["Total Corners - Away Team"];
        delete data.markets["Total Corners - Away Team"];
        data.markets["Total Corners Home Team"] = data.markets["Total Corners - Home Team"];
        delete data.markets["Total Corners - Home Team"];
        setSaving(true);


        //data.website
        //data.sports
        //data.regions
        //data.leagues
        //data.teams
        //data.markets
        //data.selections
        //data.marketGroups
        //data.overviewMarkets

        var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(data[targetKeys]));
        var downloadAnchorNode = document.createElement('a');
        downloadAnchorNode.setAttribute("href", dataStr);
        downloadAnchorNode.setAttribute("download", `${targetKeys}.json`);
        document.body.appendChild(downloadAnchorNode); // required for firefox
        downloadAnchorNode.click();
        downloadAnchorNode.remove();


        setSaving(false);

    };

    const publish = () => {
        setSaving(true);
        var languageKeys = {};
        languages.map(x => {
            languageKeys[x.key] = x.key;
        });

        API.post(CMSEndpoints.PublishWebSiteLanguage, languageKeys, 'publishing language', 'publish failed').then(
            (result) => {
                Popper.pop('information', 'Language published.');
                var copy = data;
                copy.version = result.result.split(':')[1];
                setData(copy);
                setSaving(false);
            }
        );
    };

    const remove = (key) => {
        var copy = { ...data };
        delete copy[targetKeys][key];


        copy.captions = {
            language: prepareCaptions(copy.website),
            marketGroups: prepareCaptions(copy.marketGroups),
        };

        setData(copy);
    };

    const removeSelected = () => {
        setSaving(true);
        API.post(`${CMSEndpoints.DeleteLanguageKey}`, { keys: Object.keys(selectedKeys) }, 'please wait', 'unable to update key').then(() => {
            setSaving(false);
            var copy = { ...data };

            Object.keys(selectedKeys).map(key => {
                delete copy[targetKeys][key]
            });

            setSelectedKeys({});
            setData(copy);
        });
    };

    const update = (value, key) => {
        var copy = { ...data };
        if (value == '') {
            delete copy[targetKeys][edit][key];
        } else {
            copy[targetKeys][edit][key] = value;
        }
        setData(copy);
    };

    const addNew = () => {
        const newKeyRef = React.createRef();
        application().modal.open(<ConfirmationModal title='New Key'
            onConfirm={
                () => {
                    var copy = { ...data };
                    if (newKeyRef.current.value == '') return;
                    if (copy.website.hasOwnProperty(newKeyRef.current.value) || copy.website.hasOwnProperty(newKeyRef.current.value.toLocaleLowerCase())) {
                        application().modal.open(<ErrorModal title='Error'>
                            <p>{lang('Can not add an existing key')}</p>
                        </ErrorModal>);
                        return;
                    }

                    copy[targetKeys][newKeyRef.current.value] = {};
                    copy.captions[targetKeys] = prepareCaptions(copy[targetKeys]);

                    setData(copy);
                    setFilter(newKeyRef.current.value[0]);
                    setEdit(newKeyRef.current.value);
                    setSearch(newKeyRef.current.value);
                    searchRef.current.value = newKeyRef.current.value;
                }
            }>
            <input type='text' style={{ width: '100%' }} ref={newKeyRef} />
        </ConfirmationModal>);
    };

    const getModel = () => {
        var fields = {
            fields:
                [
                    {
                        title: 'Key',
                        name: 'id',
                        render: (val, row, field, context) => <input
                            type='text'
                            name={field.name}
                            title={val}
                            value={val} />
                    },
                ]
        }
        fields.fields = fields.fields.concat(languages.map(x => {
            return {
                title: `${x.name} - (${x.key})`,
                name: x.key,
                render: (val, row, field, context) => {
                    return <input
                        key={row.id + '_' + x.key + '_' + field.name}
                        type='text'
                        name={field.name}
                        defaultValue={val}
                        value={val}
                        onFocus={defaultFocus}
                        onChange={(e) => context.update(e, row)} />;
                }

            }
        }))
        return fields;
    }

    const context = {
        update: (e, row) => {
            var value = e.target.value;
            var copy = { ...data }
            copy[targetKeys][row.id][e.target.name] = value;
            setData(copy);
        }
    };

    const prepareLeagueData = (selectedCategory, selectedRegion, leagues) => {
        if (leagues) {
            if (searchFilter) {
                leagues = leagues.filter(x => x?.name?.toLowerCase()?.includes(searchFilter.toLowerCase()));
            }
            var copy = { ...data };
            var setFilters = true;
            if (leagues.length <= 20) {
                setFilters = false;
                setFilter('');
            }

            for (let i = 0; i < leagues.length; i++) {
                const league = leagues[i];
                const exists = data.leagues[`${league.id}|${league.name}`];
                if (setFilters && i == 0) {
                    setFilter(league.name[0].toLowerCase());
                }
                if (exists) {

                    //we added this fields new, its only for languages pages, so we need to update exist data
                    copy.leagues[`${league.id}|${league.name}`]['id'] = `${league.id}|${league.name}`;
                    copy.leagues[`${league.id}|${league.name}`]['_regionId'] = selectedRegion.id;
                }
                else {
                    copy.leagues[`${league.id}|${league.name}`] = {
                        id: `${league.id}|${league.name}`,
                        En: league.name,
                        _metaId: league.id,
                        _metaName: `${league.sportName}/${league.regionName}/${league.name}`,
                        _regionId: selectedRegion.id
                    };
                }
            }
            copy.captions.leagues = prepareCaptions(leagues.map(x => x.name).reduce((acc, currentValue) => {
                acc[currentValue] = currentValue;
                return acc;
            }, {}), false);
            setData(copy);
        } else {
            setFilter('');
        }
    }

    const prepareTeamData = (selectedCategory, selectedRegion, selectedLeague, teams) => {
        if (teams) {
            if (searchFilter) {
                teams = teams.filter(x => x?.name?.toLowerCase()?.includes(searchFilter.toLowerCase()));
            }
            var copy = { ...data };
            var setFilters = true;
            if (teams.length <= 20) {
                setFilters = false;
                setFilter('');
            }

            for (let i = 0; i < teams.length; i++) {
                const team = teams[i];
                const exists = data.teams[`${team.id}|${team.name}`];
                if (setFilters && i == 0) {
                    setFilter(team.name[0].toLowerCase());
                }

                if (exists) {

                    //we added this fields new, its only for languages pages, so we need to update exist data
                    copy.teams[`${team.id}|${team.name}`]['id'] = `${team.id}|${team.name}`;
                    copy.teams[`${team.id}|${team.name}`]['_leagueId'] = selectedLeague.id;

                }
                else {
                    copy.teams[`${team.id}|${team.name}`] = {
                        id: `${team.id}|${team.name}`,
                        En: team.name,
                        _metaId: team.id,
                        _metaName: `${team.sportName}/${team.name}`,
                        _leagueId: selectedLeague.id
                    };

                }
            }
            copy.captions.teams = prepareCaptions(teams.map(x => x.name).reduce((acc, currentValue) => {
                acc[currentValue] = currentValue;
                return acc;
            }, {}), false);
            setData(copy);
        } else {
            setFilter('');
        }
    }

    const getData = () => {
        var res = Object.values(data[targetKeys]);
        var searchOnValues = false;
        if (search) {
            res = res.filter(x => {
                if (x?.id?.toLowerCase()?.includes(search.toLowerCase())) {
                    return true;
                }
                if (x) {
                    for (let key in x) {
                        if (x.hasOwnProperty(key)) {
                            if (x[key]?.toString()?.toLowerCase()?.includes(search.toLowerCase())) {
                                if (!searchOnValues) {
                                    searchOnValues = true;
                                }
                                return true;
                            }
                        }
                    }
                }
                return false;
            });
        }

        if (regionfilter && regionfilter > 0) {
            res = res.filter(x => x?._regionId == regionfilter);
        }
        if (leaguefilter && leaguefilter > 0) {
            res = res.filter(x => x?._leagueId == leaguefilter);
        }
        
        res = res.filter(function (x) {
            if (searchOnValues) return true;

            if (!x?.id) return false;
            var hasMeta = x.id.indexOf('|') > 0;
            if (search) {
                if (filter == search[0]) {
                    if (!hasMeta && (filter && x.id && x.id[0].toLowerCase() !== filter)) return false;
                    if (hasMeta && (filter && x.id && x.id.split('|')[1][0].toLowerCase() !== filter)) return false;
                } else {
                    setFilter(search[0]);
                }
            }
            else {
                if (!hasMeta && (filter && x.id && x.id[0].toLowerCase() !== filter)) return false;
                if (hasMeta && (filter && x.id && x.id.split('|')[1][0].toLowerCase() !== filter)) return false;
            }
            return true;
        })

        return res.sort(function (a, b) {
            if (!a?.id) return -1;
            if (!b?.id) return 1;
            var hasMetaA = a.id.indexOf('|') > 0;
            var hasMetaB = b.id.indexOf('|') > 0;
            if (hasMetaA && hasMetaB) {
                return a.id.split('|')[1].toLowerCase() > b.id.split('|')[1].toLowerCase() ? 1 : -1;
            } else if (hasMetaA && !hasMetaB) {
                return a.id.split('|')[1].toLowerCase() > b.id.toLowerCase() ? 1 : -1;

            } else if (!hasMetaA && hasMetaB) {
                return a.id.toLowerCase() > b.id.split('|')[1].toLowerCase() ? 1 : -1;

            } else {
                return a.id.toLowerCase() > b.id.toLowerCase() ? 1 : -1;
            }
        });
    }

    if (!loaded) return <></>;

    return <div className={`language-configuration${saving ? ' disabled' : ''} overflow-hidden`}>
        <FluxCard>
            <flex className='align-items-center justify-content-between'>
                <flex className='vertical gap-10'>
                    <flex className='gap-10'>
                        <Button title='Add new' onClick={() => addNew()} className='hidden' />
                        {
                            Object.keys(CMSKeys).map(x => {
                                return <Button title={CMSKeys[x]} className={x == targetKeys ? ' success' : ''} onClick={() => setTargetKeys(x)} key={x} />
                            })
                        }
                    </flex>
                    {(() => {
                        switch (CMSKeys[targetKeys]) {
                            case 'a':
                                return '';
                            default:
                                return <flex className='letters padding'>
                                    {
                                        (data.captions[targetKeys]).map(x => {
                                            return <div key={x} onClick={() => setFilter(x.toLocaleLowerCase())} className={`border radius shadow${filter == x.toLocaleLowerCase() ? ' selected' : ''}`}>
                                                <label>{x}</label>
                                            </div>;
                                        })}
                                </flex>

                        }
                    })()}
                </flex>
                <flex className='vertical gap-5'>
                    <flex className='align-right gap-5'>
                        <Button title='Save' className='success' onClick={() => save()} />
                        <Button title='Publish' className='success' onClick={() => publish()} />
                        <Button title='Downlaod' className='success' onClick={() => download()} />
                    </flex>
                    {saving ? <flex className='gap-5'><label>{lang('Saving')}</label></flex> : <flex className='gap-5 align-items-end justify-content-end'>
                        <span>{data?.version}</span>
                    </flex>
                    }
                </flex>
            </flex>
        </FluxCard>
        {
            Object.keys(selectedKeys).length > 0 &&
            <FluxCard>
                <flex className='letters gap-10'>
                    <label>Total selected {Object.keys(selectedKeys).length}</label>
                    <Button title='Delete selected' onClick={() => removeSelected()} />
                </flex>
            </FluxCard>
        }
        <FluxCard title={CMSKeys[targetKeys]} className='keys overflow-hidden'
            buttons={
                <flex className='gap-5 align-right'>
                    <label>{lang('Search')}</label><input type='text' ref={searchRef} />
                    <Button title={'Search'} onClick={() => {
                        setSearch(searchRef.current.value.toLocaleLowerCase());
                    }} />
                </flex>}>
            <flex className='gap-10 fit-children select-fixture-container'>
                {targetKeys == 'leagues' && <FluxCard
                    buttons={<Button onClick={() => minimizeMenu(!minimized)}><Icon className='minimize-button' icon={minimized ? 'right-to-line' : 'left-to-line'} type='fa-light' /></Button>}
                    title={minimized ? '' : 'Select Fixture'}
                    className={'no-padding ' + (minimized ? 'select-fixture-minimized' : 'select-fixture')}>
                    <div className={minimized ? 'hidden' : ''}>
                        <SportsTree regions leagues
                            onSearch={(e) => setSearchFilter(e)}
                            onLeagueSelected={(x) => setFilter(x.name[0].toLocaleLowerCase())}
                            onLeaguesFetched={(selectedCategory, selectedRegion, leagues) => prepareLeagueData(selectedCategory, selectedRegion, leagues)}
                            onRegionSelected={((a, b) => setRegionFilter(b?.id ?? 0))}
                        /></div>
                </FluxCard>}
                {targetKeys == 'teams' && <FluxCard
                    title={minimized ? '' : 'Select Fixture'}
                    buttons={<Button onClick={() => minimizeMenu(!minimized)}><Icon className='minimize-button' icon={minimized ? 'right-to-line' : 'left-to-line'} type='fa-light' /></Button>}
                    className={'no-padding ' + (minimized ? 'select-fixture-minimized' : 'select-fixture')}>
                    <div className={minimized ? 'hidden' : ''}>
                        <SportsTree regions leagues teams
                            onSearch={(e) => setSearchFilter(e)}
                            onTeamSelected={(x) => setFilter(x.name[0].toLocaleLowerCase())}
                            onTeamsFetched={(selectedCategory, selectedRegion, selectedLeague, teams) => prepareTeamData(selectedCategory, selectedRegion, selectedLeague, teams)}
                            onLeagueSelected={((a) => setLeagueFilter(a?.id ?? 0))}
                        />
                    </div>
                </FluxCard>}
                <flex className='vertical gap-10 align-start select-fixture-detail scroll-y'>
                    <Table
                        data={getData()}
                        model={getModel()}
                        key={targetKeys}
                        context={context}
                        className={'fx-borders fx-shadow limits-table language-table'}
                    />
                </flex>
            </flex>
        </FluxCard>
    </div>;
};