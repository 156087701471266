/* eslint-disable react/display-name */
import React from 'react';
import { useSelector } from 'react-redux';
import { ReportEndpoints } from '../../../../v2/Lib/Api/Api';
import { InputTypes } from '../../../components/form/InputTypes';
import { FluxSearch } from '../../../components/search/FluxSearch';
import { TransactionTypes } from '../../../lib/Enums/TransactionEnums';
import { DateHelpers } from '../../../lib/Helpers/DateHelpers';
import { gotoProfile } from '../../../lib/Helpers/NavigationHelper';
import { CurrencyFilterField } from '../../../lib/Models/CurrencyModel';

export const searchModel = {
    fields: [
        {
            name: 'TransactionDate',
            title: 'Date',
            type: InputTypes.DateTime,
            nullable: true,
            between: true,
            value: {
                start: DateHelpers.startOfWeek(),
                end: DateHelpers.tonight()
            }
        },
        {
            name: 'PlayerId',
            title: 'Player Id',
            type: InputTypes.Number,
        },
        {
            name: 'RetailId',
            title: 'Retail Id',
            nullable: true,
            type: InputTypes.Number
        },
        {
            name: 'RetailCode',
            title: 'Retail Code'
        },
        {
            name: 'Code',
            title: 'Code',
            type: InputTypes.Text,
        },
        {
            name: 'Username',
            type: InputTypes.Text,
        },
        {
            name: 'Group',
            title: 'Player Group',
            type: InputTypes.Select,
            exact: true,
            nullable: true,
            data: {
                source: 'myPlayerGroups',
                key: 'Id',
                title: ['Name']
            }
        },
        CurrencyFilterField,
        {
            name: 'Retails',
            title: 'Only Retails',
            type: InputTypes.Bool
        }
    ]
};

export const resultModel = {
    fields: [
        {
            name: 'TransactionDate',
            title: 'Date',
            type: InputTypes.DateTime
        },
        {
            name: 'UserId',
            title: 'Player Id',
            type: InputTypes.Number,
        },
        {
            name: 'Username',
            type: InputTypes.Text,
            render: (val, row) => {
                return <a style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => {
                    gotoProfile(row.UserId);
                }}>{val}</a>;
            }
        },
        {
            name: 'PlayerName',
            title: 'Name Surname'
        },
        {
            name: 'Operator'
        },
        {
            name: 'RetailName',
            title: 'Retail'
        },
        {
            name: 'PlayerGroup',
            title: 'Group'
        },
        {
            name: 'Type',
            title: 'Type',
            render: (val) => {
                return TransactionTypes[val];
            }
        },
        {
            name: 'Amount',
            title: 'Amount'
        },
        {
            name: 'Currency',
            title: 'Currency'
        },
        {
            name: 'OperatorUsername',
            title: 'Admin'
        }
    ]
};

export const ReportAccountSummaryTransactions = () => {
    const user = useSelector((state) => state.user.loginInformation);
    const singleOperator = user.UserPrivileges.Operators.length == 1;


    return <FluxSearch
        model={searchModel}
        endpoint={ReportEndpoints.TransactionsAccountSummaryReport}
        resultModel={resultModel}
        onPrepareResultModel={(model) => {
            var copy = { ...model };

            if (singleOperator) {
                model.fields.find(x => x.name == 'Operator').hidden = true;
            }
            return copy;
        }}
    />;
};
