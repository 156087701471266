/* eslint-disable react/display-name */
import React from 'react';
import { InputTypes } from '../../../components/form/InputTypes';
import { FluxSearch } from '../../../components/search/FluxSearch';
import { DateHelpers } from '../../../lib/Helpers/DateHelpers';
import { RetailTransactionTypes } from '../../../lib/Enums/RetailEnums';
import { Currency } from '../../../components/currency/Currency';
import { footerCurrencyValues } from '../../../lib/Helpers/Reports/Footer';
import { ReportEndpoints } from '../../../lib/API/Endpoints';

export const searchModel = {
    fields: [
        {
            name: 'EntryDate',
            title: 'Date',
            type: InputTypes.DateTime,
            nullable: true,
            between: true,
            value: {
                start: DateHelpers.startOfWeek(),
                end: DateHelpers.tonight()
            }
        },
        {
            name: 'Type',
            title: 'Transaction Type',
            type: InputTypes.Select,
            values: RetailTransactionTypes,
            nullable: true,
        },
        {
            name: 'Code',
            title: 'Code',
            type: InputTypes.Text,
        }
    ]
};

export const resultModel = {
    fields: [
        {
            name: 'RetailCode',
            title: 'Code'
        },
        {
            name: 'RetailName'
        },
        {
            name: 'Currency',
        },
        {
            name: 'TotalDepositToPlayer',
            title: 'Deposit to Player',
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        },
        {
            name: 'TotalWithdrawFromPlayer',
            title: 'Withdraw from Player',
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        },
        {
            name: 'TotalDepositToRetail',
            title: 'Deposit to Retail',
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        },
        {
            name: 'TotalWithdrawFromRetail',
            title: 'Withdraw from Retail',
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        },
        {
            name: 'Balance',
            title: 'Balance',
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        }
    ]
};

export const ReportAccountSummaryRetails = () => {
    return <FluxSearch
        model={searchModel}
        endpoint={ReportEndpoints.RetailsAccountSummaryReport}
        resultModel={resultModel}
    />;
};
