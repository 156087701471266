import React, { useEffect, useState } from 'react';
import { FluxForm } from '../../../../components/form/FluxForm';
import { InputTypes } from '../../../../components/form/InputTypes';



const filterModel = {
    fields: [
        {
            name: 'Sports',
            title: 'Sports',
            type: InputTypes.Select,
            multiple: true,
            nullable: true,
            values: []
        },
        {
            name: 'Regions',
            title: 'Regions',
            type: InputTypes.Select,
            multiple: true,
            nullable: true,
            values: []
        },
        {
            name: 'Leagues',
            title: 'Leagues',
            type: InputTypes.Select,
            multiple: true,
            nullable: true,
            values: []
        },
        {
            name: 'Events',
            title: 'Events',
            type: InputTypes.Select,
            multiple: true,
            nullable: true,
            values: []
        },
        {
            name: 'BetType',
            title: 'Bet Type',
            type: InputTypes.Select,
            multiple: false,
            nullable: true,
            values: [
                {
                    title: 'Prematch',
                    value: 0,
                },
                {
                    title: 'Live',
                    value: 1,
                }
            ]
        },
        {
            name: 'TicketType',
            title: 'Ticket Types',
            type: InputTypes.Select,
            multiple: false,
            nullable: true,
            values: [
                {
                    title: 'Single',
                    value: 0,
                },
                {
                    title: 'Multi',
                    value: 1,
                }
            ]
        },
        {
            name: 'Stake',
            title: 'Stake',
            type: InputTypes.Number,
            between: true,
            value: {
                start: 0,
                end: 0
            }
        },
        {
            name: 'PossibleWin',
            title: 'Potential Win',
            type: InputTypes.Number,
            between: true,
            value: {
                start: 0,
                end: 0
            }
        },
        {
            name: 'Odds',
            title: 'Odds',
            type: InputTypes.Number,
            between: true,
            value: {
                start: 0,
                end: 0
            }
        },
        {
            name: 'Legs',
            title: 'Number of legs',
            type: InputTypes.Number,
            between: true,
            value: {
                start: 0,
                end: 0
            }
        },
        {
            name: 'Currency',
            type: InputTypes.Select,
            nullable: true,
            data: {
                source: 'enabledCurrencies',
                key: 'CurrencyCode',
                title: ['Name']
            }
        },
    ]
};


export const TickerFilters = (props) => {
    const { visible, sports, regions, leagues, events, onFilter } = { ...props };

    const [model, setModel] = useState(null);

    useEffect(() => {
        var copy = { ...filterModel };

        copy.fields.find(x => x.name == 'Sports').values = Object.keys(sports).map(x => { return { title: x.split(':').reverse()[0], value: x.split(':')[0] }; });
        copy.fields.find(x => x.name == 'Regions').values = Object.keys(regions).map(x => { return { title: x.split(':').reverse()[0], value: x.split(':')[0] }; });
        copy.fields.find(x => x.name == 'Leagues').values = Object.keys(leagues).map(x => { return { title: x.split(':').reverse()[0], value: x.split(':')[0] }; });
        copy.fields.find(x => x.name == 'Events').values = Object.keys(events).map(x => { return { title: x.split(':').reverse()[0], value: x.split(':')[0] }; });
        setModel(copy);
    }, []);

    if (!visible) return <></>;

    if (!model) return <></>;
    return <flex className='vertical gap-10'>
        <FluxForm model={model} className='horizontal' columns={5} onSubmit={(data) => {
            onFilter(data);
        }} />
    </flex>;
};