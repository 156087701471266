import './_sport-event.scss';

import React, { useEffect, useState } from 'react';
import { ActionButton, FluxCard } from '../../card/FluxCard';
import { EventScoreSummary } from '../Ticket/Score/EventScoreSummary';
import { convertLSportIdToBetradarSportId } from '../../../lib/Helpers/LsportConversationTools';
import { EventStatus } from '../../../lib/Enums/BettingEnums';
import { TraderEndpoints } from '../../../lib/API/Endpoints';
import { useSelector } from 'react-redux';
import { API } from '../../../lib/API/Api';
import { Table } from '../../table/Table';
import { Button } from '../../button/Button';
import { lang } from '../../../lib/Localization/language';


// 10576122
// 10651442 tennis

const FootballTypes = {
    1: 'Corners',
    2: 'Shots On Target',
    3: 'Shots Off Target',
    4: 'Attacks',
    5: 'Dangerous Attacks',
    6: 'Yellow Card(s)',
    7: 'Red Card(s)',
    8: 'Penalties',
    9: 'Goal(s)',
    10: 'Substitutions',
    11: 'Possession',
    12: 'Fouls',
    13: 'Free Kicks',
    14: 'Goal Kicks',
    15: 'Offsides',
    16: 'Blocked Shots',
    17: 'Throw Ins',
    18: 'Woodwork Shots',
    19: 'Clearance',
    24: 'Own Goal',
    27: 'Score',
    40: 'MissedShootoutPenalties',
    117: 'Goal Attempts',
    1036: 'Player Assists'
};

const BasketballTypes = {
    12: 'Fouls',
    27: 'Score',
    28: 'Two points',
    30: 'Three points',
    31: 'Time outs',
    32: 'Free throw'
};


const TennisTypes = {
    20: 'Aces',
    21: 'DoubleFaults',
    22: 'Service Points',
    23: 'BreakPoints',
    27: 'Score',
    34: 'FirstServeWins',
    47: 'LongestPointStreak',
    52: 'LongestAcesStreak',
    55: 'NumberOfPoints',
    56: 'Last10GamesBreakPointConversion',
    59: 'FiveGameStreakInRow',
    60: 'LastGamesWonToLove',
    61: 'LastGamesWonTill15',
    62: 'LastGamesBrokenStreak',
    63: 'SecondServeReturnPointsWon',
    64: 'Last15PointsWon',
    65: 'ServiceGameWon',
    66: 'BreakPointsConverted',
    67: 'BreakPointsSaved',
    68: 'SecondServeWins',
    69: 'FirstServesIn',
    70: 'FirstServeReturnPointsWon',
    71: 'ReturnGamesWon',
    72: 'Momentum'
};


const getScore = (score, target) => {
    if (!score) return;
    return score.find(x => x.Position == target)?.Value;
};

const Team = (props) => {
    const { event, score, home } = { ...props };

    const [teamName, setTeamName] = useState(home ? 'home' : 'away');
    const [image, setImage] = useState(null);


    useEffect(() => {
        if (!event?.competitors) {
            return;
        }

        try {
            var converted = JSON.parse(event.competitors);

            var teamInformation = home ? converted.find(x => x.Position == '1') : converted.find(x => x.Position == '2');
            setTeamName(teamInformation.Name);
            setImage(`medium/${teamInformation.Id}.png`);
        } catch (err) {
            //
            console.log(err);
        }
    }, []);

    return <flex className='vertical sport-team align-items-center gap-10'>
        <flex className='team-jersey fx-shadow'>
            <img src={`//cdn-d.tentangle.com/jersey/${image}`} onError={(e) => {
                e.target.src = '//cdn-d.tentangle.com/jersey/0.png';
            }} />
        </flex>
        <label className='medium'>{teamName}</label>
        <span className=''>{score}</span>
    </flex>;
};



export const EventStatistics = (props) => {
    const { event } = { ...props };
    const score = event?.liveScore;
    var convertedScore = score ? JSON.parse(score) : {};


    const renderStatType = (sport, stat) => {

        switch (sport.toString()) {
            case '6046':
                return FootballTypes[stat.Type];
            case '48242':
                return BasketballTypes[stat.Type];
            case '54094':
                return TennisTypes[stat.Type];
        }
    };

    const renderSportStatistics = (sport, stats) => {
        return stats?.map((x, index) => {
            var homeScore = parseInt(getScore(x.Results, '1'));
            var awayScore = parseInt(getScore(x.Results, '2'));

            var total = homeScore + awayScore;

            var homeWidth = homeScore * 100 / total;
            var awayWidth = 100 - homeWidth;

            return <flex className='gap-10 stat' key={x}>
                <span className='home-score'>{homeScore}</span>
                <flex className='vertical stat-progress'>
                    <label className='medium'>{renderStatType(sport, x)} ({total})</label>
                    <flex className='bar'>
                        <flex className='home' style={{ width: homeWidth + '%' }} />
                        <flex className='away' style={{ width: awayWidth + '%' }} />
                    </flex>
                </flex>
                <span className='away-score'>{awayScore}</span>
            </flex>
        });
    };

    return <FluxCard title='Statistics' className={'event-statistics'}>
        <flex className='vertical'>
            {renderSportStatistics(event?.sport, convertedScore?.Statistics)}
        </flex>
    </FluxCard>
};


export const Event = (props) => {
    const user = useSelector((state) => state.user).loginInformation;
    const operatorId = user.UserPrivileges.PointOfView;
    const { id, ticketNo, onClose, showAll } = { ...props };
    const [event, setEvent] = useState(null);

    const [updateKey, setUpdateKey] = useState(0);
    const [loading, setLoading] = useState(false);

    const [data, setData] = useState(null);

    const score = event?.liveScore;
    var convertedScore = score ? JSON.parse(score) : {};

    useEffect(() => {
        load();
    }, []);

    const load = () => {
        API.post(TraderEndpoints.GetEventsOpenSelections(operatorId, id, showAll), null).then((result) => {
            setEvent(result.result.event);

            setUpdateKey(Date.now());
            setData(result.result);
            setLoading(false);
        });
    };

    useEffect(() => {
    }, [event?.id]);

    const renderDate = () => {

        var date = new Date(event.Date);
        return <flex className='vertical gap-5 align-items-center justify-content-center'>
            <label className='medium'>{EventStatus[event.status]}</label>
            <flex className='gap-5'>
                <span>{date.month}</span>
                <span>{date.day}</span>
                <span>{date.year}</span>
            </flex>
            <label className='medium'>{date.time}</label>
        </flex>;
    };


    const prepareData = (row, settlementStatus) => {
        return {
            Events: [
                {
                    FixtureId: props.id,
                    Markets: [
                        {
                            Id: row.marketId,
                            Providers: [
                                {
                                    LastUpdate: new Date().toISOString(),
                                    Bets: [
                                        {
                                            Id: row.selectionId,
                                            MarketId: row.marketId,
                                            Status: 3,
                                            Settlement: settlementStatus
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ],
            selectedSelection: row,
            event: data,
            userId: user.Id,
            operatorId: operatorId,
            ticketNo: ticketNo
        };
    };


    const win = (row) => {
        setLoading(true);
        API.post(TraderEndpoints.SendSettlement(operatorId, event.id), prepareData(row, 2))
            .then(() => {
                setData(null);
                setTimeout(() => {
                    load();
                }, 3000);
            }, () => setLoading(false));
    };

    const lost = (row) => {
        setLoading(true);
        API.post(TraderEndpoints.SendSettlement(operatorId, event.id), prepareData(row, 1)).then(() => {
            setData(null);
            setTimeout(() => {
                load();
            }, 3000);
        }, () => setLoading(false));
    };

    const refund = (row) => {
        setLoading(true);
        API.post(TraderEndpoints.SendSettlement(operatorId, event.id), prepareData(row, 3)).then(() => {
            setData(null);
            setTimeout(() => {
                load();
            }, 3000);
        }, () => setLoading(false));
    };

    const halfLost = (row) => {
        setLoading(true);
        API.post(TraderEndpoints.SendSettlement(operatorId, event.id), prepareData(row, 4)).then(() => {
            setData(null);
            setTimeout(() => {
                load();
            }, 3000);
        }, () => setLoading(false));
    };

    const halfWin = (row) => {
        setLoading(true);
        API.post(TraderEndpoints.SendSettlement(operatorId, event.id), prepareData(row, 5)).then(() => {
            setData(null);
            setTimeout(() => {
                load();
            }, 3000);
        }, () => setLoading(false));
    };


    if (!event) return <></>;

    return <flex className={'vertical gap-10 sport-teams' + (loading ? ' disabled' : '')} key={event?.id}>
        <FluxCard title={event?.sportName + ' / ' + event?.regionName + ' / ' + event?.name} className={''} buttons={<flex className='align-right'>
            <ActionButton icon='times' title='Close' onClick={() => {
                onClose();
            }} />
        </flex>}>
            <flex className='vertical gap-10'>
                <flex className='gap-20 justify-content-center'>
                    <flex className='vertical'>
                        <Team home event={event} score={getScore(convertedScore.Scoreboard?.Results, 1)} />
                    </flex>
                    <flex className='vertical gap-5 align-items-center justify-content-center'>
                        {renderDate()}
                        <EventScoreSummary
                            onlyPeriods
                            event={{
                                sport: {
                                    id: convertLSportIdToBetradarSportId(event?.sport)
                                }
                            }} statistics={convertedScore} />
                    </flex>
                    <flex className='vertical'>
                        <Team away event={event} score={getScore(convertedScore.Scoreboard?.Results, 2)} />
                    </flex>
                </flex>
            </flex>
        </FluxCard>
        <flex className='gap-10 fit-children overflow-hidden'>
            <EventStatistics event={event} />
            <FluxCard title={<flex className='gap-10 fit'>
                <span className='bold'>{lang('Selections')}</span>
                <flex className='align-right'>
                    <Button title={'Refresh'} className={'success'} onClick={() => load()} />
                </flex>
            </flex>} >
                <Table model={{
                    fields: [
                        {
                            name: 'marketId',
                            title: 'Market Id'
                        },
                        {
                            name: 'marketName',
                            title: 'Market Name'
                        },
                        {
                            name: 'selectionId',
                            title: 'Selection Id'
                        },
                        {
                            name: 'selectionName',
                            title: 'Selection Name'
                        },
                        {
                            name: 'specifiers',
                            title: 'Specifiers'
                        }
                    ]
                }} key={updateKey} data={data?.selections ?? []} className='border radius shadow' buttons={(row) => {
                    return <div className='flex gap-5'>
                        <Button title='WIN' className='success' onClick={() => win(row)} />
                        <Button title='LOST' className='warning' onClick={() => lost(row)} />
                        <Button title='REFUND' className='' onClick={() => refund(row)} />
                        <Button title='HALF LOST' className='success' onClick={() => halfLost(row)} />
                        <Button title='HALF WIN' className='success' onClick={() => halfWin(row)} />
                    </div>;
                }} />
            </FluxCard>
        </flex>
    </flex >;
};