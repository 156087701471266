import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import './_table.scss';

import TableRow from './TableRow';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from './arrayMove';
import { TableHead } from './TableHead';
import { TableFooter } from './TableFooter';
import { Button } from '../button/Button';
import { lang } from '../../lib/Localization/language';
import { Icon } from '../icon/Icon';



const SortableCont = SortableContainer(({ children }) => {
    return (
        <tbody className='flux-table-body'>
            {children}
        </tbody>
    );
});

const SortableItem = SortableElement(props => <TableRow {...props} />);


const createModel = (model) => {
    if (typeof (model) === 'object') {
        return model;
    }
    return null;
};

const createOptions = (options) => {
    if (!options) {
        options = {
            data: {
                delete: {
                    enabled: false,
                    header: false
                }
            },
            header: {
                show: true,
            },
            draggable: {
                enabled: false
            },
            selectable: false
        };
    }


    if (!options.header) {
        options.header = {
            show: true
        };
    }
    return options;
};



export const Table = (props) => {
    const [model, setModel] = useState(null);
    const [data, setData] = useState(null);
    const [expandedRows, setExpandedRows] = useState(null);
    const options = createOptions(props.options);
    const [maxRows, setMaxRows] = useState(props.options?.pagination?.rows);
    const [page, setPage] = useState(null);
    const itemsPerPage = props.options?.pagination?.rows ?? 20;
    const [pagination, setPagination] = useState(null);
    const [highlightedRow, highlightRow] = useState(null);

    const tableContext = {
        expandRow: (index, data) => {
            var copy = Object.assign({}, expandedRows);
            copy[index] = data;
            setExpandedRows(copy);
        },
        setData: (data) => setData(data),
        getData: () => [...data]
    };

    var table = null;

    useEffect(() => {
        if (data && data.length) setPage(0);
    }, [data]);

    useEffect(() => {
        if (!data) return;
        let current = page;
        let totalItems = options?.pagination?.total ?? data.length;
        let totalPages = Math.ceil(totalItems / itemsPerPage);


        if (current > totalItems) {
            current = (totalPages - 1) * itemsPerPage;
        }

        let start = current * itemsPerPage;
        var pages = [];
        for (var i = 0; i < totalPages; i++) {
            pages.push({ index: i });
        }


        var paginationData = {
            start: current * itemsPerPage,
            end: start + itemsPerPage,
            total: totalPages,
            pages: pages,
        };
        setPagination(paginationData);

        if (options?.pagination?.onPageChange) {
            options.pagination.onPageChange(paginationData)
        }
    }, [page]);

    const onSortStart = useCallback(({ node, index, collection, isKeySorting }) => {
        table.classList.add('drag-started');
        if (props.onSortStart) {
            props.onSortStart(node, index, collection, isKeySorting);
        }
        node.classList.remove('row-highlighted');
    });

    const onSortEnd = useCallback(({ oldIndex, newIndex }) => {
        table.classList.remove('drag-started');

        var items = oldItems => {
            var res = arrayMove(oldItems, oldIndex, newIndex);
            props.onDataChange(null, null, res);
            return res;
        };

        if (props.onSortEnd) {
            props.onSortEnd(oldIndex, newIndex, arrayMove([...data], oldIndex, newIndex));
        }

        if (highlightedRow?.index == oldIndex) {
            data[oldIndex].__highlighted = false;
            data[newIndex].__highlighted = true;
            highlightedRow.row = data[newIndex];
            highlightedRow.element = table.getElementsByTagName('tbody')[0].children[newIndex];
            highlightedRow.element.classList.add('row-highlighted');
        }
        setData(items);
    });

    const onSelect = (row) => {
        var foundRow = data.find(x => x == row);
        if (foundRow) {
            var result = Object.assign([], data);
            props.onDataChange(null, null, result);
        }
    };

    const onSelectAll = () => {
        const allSelected = data.filter(x => !x.__selected).length == 0;
        data.map(x => {
            x.__selected = allSelected ? false : true;
        });
        props.onDataChange(null, null, data);
    };

    const onDelete = (row) => {
        data.splice(data.findIndex(x => x == row), 1);
        var result = Object.assign([], data);
        props.onDataChange(null, null, result);
    };

    const onDeleteAll = () => {
        props.onDataChange(null, null, []);
    };

    const findParentElementNode = (element, type) => {
        if (element.nodeName == type) return element;
        if (!element.parentElement) return null;
        if (element.parentElement.nodeName == type) return element.parentElement;
        return findParentElementNode(element.parentElement, type);
    };


    const handleTableClick = (e, type) => {
        try {
            const element = e.target;
            let tbody, tableRow;
            const isThead = element.className.indexOf('flux-table-header') >= 0;
            if (isThead) return;
            const isCloumn = element.className.indexOf('flux-table-body-column') >= 0;
            if (isCloumn) {
                tbody = element.parentElement.parentElement;
                tableRow = element.parentElement;
            }

            // remove expanded rows;
            const tbodyChildren = Object.assign([], tbody.children).filter(x => {
                if (x.className.indexOf('row-expanded-item') >= 0) return false;
                return true;
            });

            const index = Array.prototype.indexOf.call(tbodyChildren, tableRow);
            if (!tableRow) return;
            if (tableRow.classList.contains('row-expanded-item')) return;

            if (props.onRowClick && type == 'click') {
                props.onRowClick(data[index], tableRow, index - 1, tableContext);
            }
            if (props.onDoubleClick && type == 'doubleClick') {
                props.onDoubleClick(data[index], tableRow, index - 1, tableContext);
            }
            if (highlightedRow) {
                highlightRow(null);
            }
            for (let i = 0; i < tbodyChildren.length; i++) {
                tbodyChildren[i].classList.remove('row-highlight');
            }

            highlightRow(data[index]);
            tableRow.classList.add('row-highlight');
        } catch (err) {
            //
        }
    };

    useEffect(() => {
        if (!model) {
            setModel(createModel(props.model));
            if (options.selectable) {
                props.data.map(x => {
                    if (!x.hasOwnProperty('__selected')) {
                        x.__selected = true;
                    }
                });
            }
            setData(props.data);
            return;
        }
        if (!props.data) return;
        if (data != props.data) setData(props.data);
    });

    if (!model) return <div />;
    if (!pagination && options?.pagination?.showPages) return <div />;

    var paginated = data;

    if (options?.pagination?.rows && !options?.pagination?.showPages) {
        paginated = options?.pagination?.rows ? data?.slice(0, maxRows) : data;
    }

    if (options?.pagination?.rows && options?.pagination?.showPages && pagination) {
        paginated = (data ?? []).slice(pagination.start, pagination.end);
    }


    const renderPagination = () => {
        if (!options?.pagination) return;
        if (!pagination) return;

        switch (options?.pagination?.showPages) {
            case true:
                return <div className='flux-table-footer'>
                    <tr>
                        <th colSpan={model.fields.length + (props.buttons ? 1 : 0)}>
                            <div className='flex gap-10 padding'>
                                <div key='pagination' className='table-pagination'>
                                    <div className={page == 0 ? 'page-previous disabled' : 'page-previous'} onClick={() => setPage(page - 1)}><button>{lang('Previous')}</button></div>
                                    <div className='pages'>
                                        <button key='first' onClick={() => setPage(1)} className={page == 0 ? 'btn-first-page disabled' : 'btn-first-page'}><Icon icon={'step-backward'} /></button>
                                        {
                                            pagination.pages.map(p => {
                                                return <button key={p.index} onClick={() => setPage(p.index)} className={(page) === p.index ? 'table-pagination-current' : ''}>{p.index + 1}</button>;
                                            })
                                        }
                                        <button key='last' onClick={() => setPage(pagination.total - 1)} className={page == pagination.total - 1 || pagination.total <= 1 ? 'btn-last-page disabled' : 'btn-last-page'}><Icon icon={'step-forward'} /></button>
                                    </div>
                                    <div className={page == pagination.total - 1 || pagination.total <= 1 ? 'page-next disabled' : 'page-next'} onClick={() => setPage(page + 1)}><button>{lang('Next')}</button></div>
                                </div>
                            </div>
                        </th>
                    </tr>
                </div>;
            case false:
                return options?.pagination?.rows && !options?.pagination?.showPages && data && data.length && maxRows < data?.length && <div className='flex padding'>
                    <Button title='Load more' className='center' onClick={() => {
                        var left = maxRows + options.pagination.rows;
                        if (left > data.length) left = data.length;
                        setMaxRows(left);
                    }} />
                </div>;
        }
    }

    return (
        <table
            className={`flux-table ${props.className} ${props.tableClassName}`}
            onDoubleClick={props.onDoubleClick ? (e) => handleTableClick(e, 'doubleClick') : null}
            ref={(r) => table = r}
            onClick={(e) => {
                handleTableClick(e, 'click');
            }}
        >
            {options.header.show && (
                <TableHead
                    model={model} data={props.data}
                    context={props.context}
                    options={options}
                    buttons={props.buttons}
                    onSelectAll={onSelectAll.bind(this)}
                    onDeleteAll={onDeleteAll.bind(this)}
                />
            )}
            <SortableCont
                onSortStart={onSortStart}
                onSortEnd={onSortEnd}
                axis='y'
                lockAxis='y'
                lockToContainerEdges={true}
                lockOffset={['30%', '50%']}
                helperClass='draggable-row'
                useDragHandle={true}
            >
                {paginated.map((row, index) => (
                    <React.Fragment key={`item-${index}`}>
                        <SortableItem
                            className={expandedRows && expandedRows.hasOwnProperty(index) && 'row-expanded'}
                            index={index}
                            model={model}
                            options={options}
                            row={row}
                            context={props.context}
                            tableContext={tableContext}
                            buttons={props.buttons}
                            onChange={props.onRowChange.bind(this)}
                            onSelect={(row) => onSelect(row)}
                            onDelete={(row) => onDelete(row)}
                            onRenderRow={props.onRenderRow ? props.onRenderRow.bind(this) : null}
                        />
                        {(expandedRows && expandedRows.hasOwnProperty(index)) && <tr className='row-expanded-item'>{expandedRows[index]}</tr>}
                    </React.Fragment>
                ))}
            </SortableCont>
            {props.footer && (
                <TableFooter
                    model={model}
                    data={props.footer}
                    options={options}
                    buttons={props.buttons}
                />
            )}
            {renderPagination()}
        </table>
    );
};

Table.defaultProps = {
    className: '',
    tableClassName: '',
    model: '',
    data: [],
    options: null,
    buttons: null,
    onDataChange: () => { },
    onRowChange: () => { }
};

Table.propTypes = {
    className: PropTypes.string,
    tableClassName: PropTypes.string,
    model: PropTypes.any,
    data: PropTypes.array,
    footer: PropTypes.object,
    options: PropTypes.object,

    buttons: PropTypes.func,

    multiSelect: PropTypes.bool,

    context: PropTypes.object,

    onSortStart: PropTypes.func,
    onSortEnd: PropTypes.func,

    onRenderRow: PropTypes.func,
    onDataChange: PropTypes.func,
    onRowChange: PropTypes.func,
    onDoubleClick: PropTypes.func,
    onRowClick: PropTypes.func
};



