import './_payment-providers-page.scss';

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { lang } from '../../../lib/Localization/language';
import { Table } from '../../../components/table/Table';
import { FluxCard } from '../../../components/card/FluxCard';
import { Button } from '../../../components/button/Button';
import { Modal } from '../../../components/application/FluxApp';
import { Popper } from '../../../components/notification/FluxPopper';
import { ConfirmationModal } from '../../../components/modal/ConfirmationModal/ConfirmationModal';
import { FluxTab } from '../../../components/tab/FluxTab';
import { ErrorModal } from '../../../components/modal/ErrorModal/ErrorModal';
import { FluxNotice } from '../../../components/notification/FluxNotice';
import { API } from '../../../lib/API/Api';
import { PaymentProviders } from '../../../lib/API/Endpoints';

const CurrencyRates = (props) => {
    const [rates, setRates] = useState(props.configuration.rates ?? [
        { base: 'USD', target: 'TTD', rate: 1 },
        { base: 'USD', target: 'IQD', rate: 1 },
        { base: 'USD', target: 'TRY', rate: 1 }
    ]);

    const updateRate = (rate, value) => {
        rate.rate = value;
        setRates([...rates]);
    };

    return <div className='flex vertical gap-10'>
        <div className='flex gap-5 padding'>
            <label>Default Currency</label>
            <span>USD</span>
            <Button title='Save' className='align-right' onClick={() => {
                var errors = [];
                rates.map(x => {
                    var amount = parseFloat(x.rate);
                    if (isNaN(amount) || x.rate.indexOf(',') >= 0) {
                        errors.push(`${x.base} / ${x.target} currency value ${x.rate} is wrong, use '.' for separator.`);
                    } else if (amount == 0) {
                        errors.push(`${x.base} / ${x.target} currency value can not be zero.`);
                    } else if (amount < 0) {
                        errors.push(`${x.base} / ${x.target} currency value can not be negative.`);
                    }
                });

                if (errors.length > 0) {
                    Modal.open(<ErrorModal title={'Validation error'}>
                        <div className='flex gap-5 vertical padding'>
                            {errors.map(x => {
                                return <span key={x}>{x}</span>;
                            })}
                        </div>
                    </ErrorModal>);
                    return;
                }
                props.configuration.rates = rates;
                API.post(`${PaymentProviders.SaveConfiguration}/${props.id}`, props.configuration).then((result) => {
                    Popper.pop('information', 'Configuration updated');
                });
            }} />
        </div>
        {
            rates.map(rate => {
                return <div className='border flex gap-5 padding surface' key={rate}>
                    <span>{`${rate.base} / ${rate.target}`}</span>
                    <input type='text' className='align-right' value={rate.rate} onChange={(e) => updateRate(rate, e.target.value)} />
                </div>;
            })
        }
    </div>;
};

CurrencyRates.propTypes = {
    id: PropTypes.object,
    configuration: PropTypes.object
};

export const PaymentProvidersPage = () => {
    const [providers, setProviders] = useState([]);
    const [selectedProvider, selectProvider] = useState(null);
    useEffect(() => {
        loadProviders();
    }, []);


    const loadProviders = () => {
        API.post(PaymentProviders.Get).then((result) => {
            result.result.update = Date.now();
            setProviders(result.result);
        });
    };

    const toggleEnableDisableProvider = (provider) => {
        Modal.open(<ConfirmationModal title='Please confirm' onConfirm={() => {
            API.post(`${provider.enabled ? PaymentProviders.Disable : PaymentProviders.Enable}/${provider.id}`, null).then((result => {
                if (result.result) {
                    var copy = [...providers];
                    copy.find(x => x.id == provider.id).disabledOnFrontend = !provider.disabledOnFrontend;

                    copy.update = Date.now();
                    setProviders(copy);

                    Popper.pop('information', 'Provider configuration changed.');
                } else {
                    Popper.pop('error', 'Unable to update provider');
                }
            }));
        }}>
            <FluxNotice type='warning' title={lang('Do you want to %1 provider in frontend?', [provider.disabledOnFrontend ? 'Disable' : 'Enable'])} />
        </ConfirmationModal>);
    };


    const toggleEnableDisableFrontend = (provider) => {
        Modal.open(<ConfirmationModal title='Please confirm' onConfirm={() => {
            API.post(`${provider.disabledOnFrontend ? PaymentProviders.EnableFrontend : PaymentProviders.DisableFrontend}/${provider.id}`, null).then((result => {
                if (result.result) {
                    var copy = [...providers];
                    copy.find(x => x.id == provider.id).disabledOnFrontend = !provider.disabledOnFrontend;

                    copy.update = Date.now();
                    setProviders(copy);

                    Popper.pop('information', 'Provider configuration changed.');
                } else {
                    Popper.pop('error', 'Unable to update provider');
                }
            }));
        }}>
            <FluxNotice type='warning' title={lang('Do you want to %1 provider?', [provider.disabledOnFrontend ? 'Disable' : 'Enable'])} />
        </ConfirmationModal>);
    };

    if (selectedProvider) {
        let tabButtons = [
            {
                name: 'general',
                title: 'General'
            },
            {
                name: 'currency',
                title: 'Currency Rates'
            }
        ];

        const configuration = JSON.parse(selectedProvider.configuration);
        return <FluxCard title={`[${selectedProvider.display}] Provider Configuration`} buttons={<flex className='align-right'><Button title='Close' onClick={() => {
            selectProvider(null);
            setProviders([]);
            loadProviders();
        }} /></flex>}>
            <FluxTab buttons={tabButtons}>
                <div className='padding'>
                    <Button title={lang('%1 provider', [selectedProvider.enabled ? 'Disable' : 'Enable'])} onClick={() => toggleEnableDisableProvider()} />
                </div>
                <CurrencyRates id={selectedProvider.id} configuration={configuration} />
            </FluxTab>
        </FluxCard>;
    }

    const editDisplay = (provider) => {
        var newName = provider.display;
        Modal.open(<ConfirmationModal title='Edit display' onConfirm={() => {
            Modal.close();
            API.post(PaymentProviders.UpdateDisplay,
                {
                    id: provider.id,
                    display: newName
                },
                'please wait', ' unable to change provider name!').then((result => {
                    if (result.result) {
                        var copy = [...providers];
                        copy.find(x => x.id == provider.id).display = newName;

                        copy.update = Date.now();
                        setProviders(copy);

                        Popper.pop('information', 'Provider configuration changed.');
                    } else {
                        Popper.pop('error', 'Unable to update provider');
                    }
                })).catch(() => {
                    Modal.open(<ErrorModal title='Unable to change provider name!' />);
                });
        }}>
            <flex className='vertical'>
                <input defaultValue={provider.display} onChange={(e) => {
                    newName = e.target.value;
                }} />
            </flex>
        </ConfirmationModal>);
    };


    return <FluxCard title='Configure payment providers' className={'no-padding payment-providers-confguration'}>
        <Table
            className={'border'}
            model={{
                fields: [
                    {
                        name: 'id',
                        title: 'Id'
                    },
                    {
                        name: 'display',
                        title: 'Display',
                        render: (val, row) => {
                            return <a href='#' onClick={() => {
                                editDisplay(row);
                            }}>{val}</a>;
                        }
                    },
                    {
                        name: 'disabledOnFrontend',
                        title: 'Show in frontend',
                        // eslint-disable-next-line react/display-name
                        render: (val, row) => {
                            return <i className={`clickable fa-regular fa-1x ${!row.disabledOnFrontend ? 'fa-square-check' : 'fa-square'}`} type='fa-solid' size='2x' onClick={() => {
                                toggleEnableDisableFrontend(row);
                            }} />;
                        }
                    },
                    {
                        name: 'enabled',
                        title: 'Enabled',
                        // eslint-disable-next-line react/display-name
                        render: (val, row) => {
                            return <i className={`clickable fa-regular fa-1x ${row.enabled ? 'fa-square-check' : 'fa-square'}`} type='fa-solid' size='2x' onClick={() => {
                                toggleEnableDisableProvider(row);
                            }} />;
                        }
                    }
                ]
            }} data={providers} key={providers.update} buttons2={(row) => {
                return <Button title='Configure' onClick={() => selectProvider(row)} />;
            }} />
    </FluxCard>;
};