import './_button.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { lang } from '../../lib/Localization/language';

export const Button = (props) => {
    return <div className={`flux-button clickable ${!props.flat ? 'fx-shadow' : ''} ${props.className ?? ''}`} onClick={(e) => props.onClick ? props.onClick(e) : null} type={props.type}>
        {props.title && <span>{lang(props.title)}</span>}
        {props.children}
    </div>;
};

Button.propTypes = {
    className: PropTypes.string,
    type: PropTypes.string,
    title: PropTypes.string,
    flat: PropTypes.bool,
    onClick: PropTypes.func,
    children: PropTypes.node
};