import React from 'react';
import { lang } from '../../../lib/Localization/language';
import { Modal } from '../../../components/application/FluxApp';
import { FluxForm } from '../../../components/form/FluxForm';
import { InputTypes } from '../../../components/form/InputTypes';
import { ConfirmationModal } from '../../../components/modal/ConfirmationModal/ConfirmationModal';
import { ErrorModal } from '../../../components/modal/ErrorModal/ErrorModal';
import { API } from '../../../lib/API/Api';
import { PromotionEndpoints } from '../../../lib/API/Endpoints';
import { isNullOrEmpty } from '../../../lib/Helpers/StringHelper';
import { FluxNotice } from '../../../components/notification/FluxNotice';
import { Table } from '../../../components/table/Table';
import { FluxModal } from '../../../components/modal/FluxModal';
import { Button } from '../../../components/button/Button';
import { DateHelpers } from '../../../lib/Helpers/DateHelpers';
import { addDays } from 'date-fns';


const FreeSpinCampaignConfiguration = (props) => {
    const { currency, configuration, onChange } = { ...props };

    return <FluxForm
        onChange={(d) => onChange(d)}
        buttons={null}
        model={{
            fields: [
                {
                    name: 'currency',
                    title: 'Currency',
                    value: currency,
                    readonly: true
                },
                {
                    name: 'bet',
                    title: 'Bet',
                    value: configuration.bet,
                    readonly: true
                },
                {
                    name: 'name',
                    title: 'Campaign Name',
                    value: configuration.name,
                    required: true
                },
                {
                    name: 'betCount',
                    title: 'Free Spin Count',
                    type: InputTypes.Number,
                    value: configuration.betCount ?? 5,
                    required: true
                },
            ]
        }} />;
};


const FreespinConfiguration = (props) => {
    const { configuration, isCampaign, onChange } = { ...props };


    return <flex className='vertical gap-5'>
        <FluxForm
            onChange={(d) => onChange(d)}
            buttons={null}
            columns={2}
            model={{
                fields: [
                    {
                        name: 'bet',
                        title: 'Bet',
                        value: configuration.bet,
                        readonly: true
                    },
                    {
                        name: 'freeSpinCount',
                        title: 'Free Spin Count',
                        type: InputTypes.Number,
                        value: configuration.freeSpinCount ?? 5,
                        required: true
                    },
                    {
                        name: 'startDate',
                        title: 'Start Date',
                        type: InputTypes.DateTime,
                        value: configuration.startDate ?? DateHelpers.today(),
                        readonly: isCampaign
                    },
                    {
                        name: 'endDate',
                        title: 'End Date',
                        type: InputTypes.DateTime,
                        value: configuration.endDate ?? addDays(new Date(), 14),
                        readonly: isCampaign
                    },
                ]
            }} />
    </flex>;
};

export const createFreeSpinCampaign = (promotionId, currency, gameId, providerId, gameName, freespinGameConfiguration) => {
    var data = null;
    Modal.open(<ConfirmationModal title={lang('Create Campaign')} confirmTitle='Create' onConfirm={() => {
        data.providerFreeSpinId = freespinGameConfiguration.freeBetId;
        data.currency = freespinGameConfiguration.currency;
        data.betCount = data.betCount < 11 ? data.betCount : 10;
        data.gameId = gameId;
        data.providerId = providerId;
        data.promotionId = promotionId;

        if (isNullOrEmpty(data.name)) {
            return;
        }

        API.post(PromotionEndpoints.CreateFreespinCampaign, data, 'Please wait creating campaign!', 'Unable to create campaign').then((result) => {
            console.log(result);
        });
    }}>
        <FreeSpinCampaignConfiguration currency={currency} configuration={{
            bet: freespinGameConfiguration.bet,
            betCount: freespinGameConfiguration.betCount,
            bonusBuy: freespinGameConfiguration.bonusBuy,
            providerFreeSpinId: freespinGameConfiguration.freeBetId,
            currency: freespinGameConfiguration.currency,
            gameId: gameId,
            providerId: providerId,
            gameName: gameName,
            name: ''
        }} onChange={(d) => data = d} />
    </ConfirmationModal>);
};



export const assignFreeSpinToSelectedPlayers = (currency, gameId, promotion, selectedPlayers, freespinGameConfiguration, campaign) => {
    if (!selectedPlayers) return;
    if (Object.values(selectedPlayers).length == 0) return;
    var players = Object.values(selectedPlayers).map(x => {
        return {
            Id: x.Id,
            Operator: x.Operator ?? user.Operator,
            Username: x.Username
        };
    });




    var config = freespinGameConfiguration ?? {};
    config.promotionId = promotion?.Id ?? null;

    if (campaign) {
        config = {
            bet: campaign.Bet,
            freeSpinCount: campaign.FreeSpinCount,
            bonusBuy: false,
            currency: campaign.Currency,
            gameId: campaign.GameId,
            promotionId: campaign.PromotionId,
            campaignId: campaign.Id,
            startDate: campaign.DateStart,
            endDate: campaign.DateEnd,
            // providerCampaignId: campaign.ProviderCampaignId,
            providerCampaignId: null,//TODO: if this field is empty the code works as expected, must be follow through code to understand why
            freeBetId: campaign.ProviderFreeSpinId
        };
    }


    var data = null;
    Modal.open(<ConfirmationModal title='Please confirm'
        confirmTitle={'Yes'}
        onConfirm={() => {
            API.post(PromotionEndpoints.AssignFreeSpinToPlayers, {
                promotionId: config.promotionId,
                campaignId: campaign?.Id,
                providerFreeSpinId: config.freeBetId,
                currency: currency,
                players: players,
                gameId: gameId,
                providerCampaignId: null,//TODO: if this field is empty the code works as expected, must be follow through code to understand why
                //providerCampaignId: campaign?.ProviderCampaignId,
                freeSpinCount: data.freeSpinCount,
                betLevel: data.bet,
                startDate: data.startDate,
                endDate: data.endDate
            }).then((result) => {

                var errors = result.result.find(x => !x.result);
                Modal.open(<FluxModal title={errors ? 'Operation completed with errors' : 'Operation completed'} footer={<flex className='center'>
                    <Button title='Close' onClick={() => {
                        Modal.close();
                    }} />
                </flex>}>
                    <flex className='vertical gap-10'>
                        {errors && <FluxNotice type='warning' title='Errors' description={'Errors detected on the result list.'} />}
                        {errors && <Table data={result.result} className={'fx-borders fx-shadow'} model={{
                            fields: [
                                {
                                    name: 'id',
                                    title: 'Id',
                                    render: (val, row) => {
                                        return <flex className='vertical'>
                                            <span>{val.id}</span>
                                            <span>{selectedPlayers[row.id].Username}</span>
                                        </flex>
                                    }
                                },
                                {
                                    name: 'error',
                                    title: 'Error'
                                }
                            ]
                        }}>
                        </Table>
                        }
                        {!errors && <FluxNotice type='information' title='Free Spins Assigned!' description={'Players can use their free spins after launching new game sessions.'} />}
                    </flex>
                </FluxModal>);

            }).catch((err) => {
                Modal.open(<ErrorModal title='Operation failed'>
                    <FluxNotice type='error' title='Unable to assign free spins to players!' description={err?.error?.message ?? 'Api error! Please try later'} />
                </ErrorModal>);
            });
        }}>
        <FluxNotice type='information' title={lang('Do you confirm assigning free spin to %1 players?', [Object.keys(selectedPlayers).length])} />
        <FreespinConfiguration configuration={config} isCampaign={campaign != null} onChange={(d) => {
            data = d;
        }} />
    </ConfirmationModal >);
};
