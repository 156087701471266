/* eslint-disable react/display-name */
import React from 'react';
import { useSelector } from 'react-redux';
import { ReportEndpoints } from '../../../../v2/Lib/Api/Api';
import { Currency } from '../../../components/currency/Currency';
import { InputTypes } from '../../../components/form/InputTypes';
import { FluxSearch } from '../../../components/search/FluxSearch';
import { DateHelpers } from '../../../lib/Helpers/DateHelpers';
import { isMobile } from '../../../lib/Helpers/DeviceHelper';
import { gotoProfile } from '../../../lib/Helpers/NavigationHelper';
import { CurrencyFilterField } from '../../../lib/Models/CurrencyModel';

export const searchModel = {
    fields: [
        {
            name: 'OperatorId',
            title: 'Operator',
            type: InputTypes.Select,
            exact: true,
            nullable: true,
            data: {
                source: 'operators',
                key: 'Id',
                title: ['Name']
            }
        },
        {
            name: 'Created',
            title: 'Registration Date',
            type: InputTypes.DateTime,
            nullable: true,
            between: true,
            value: {
                start: DateHelpers.startOfWeek(),
                end: DateHelpers.tonight()
            }
        },
        {
            name: 'PlayerId',
            title: 'Player Id',
        },
        {
            name: 'Username'
        },
        {
            name: 'Code'
        },
        {
            name: 'RetailId',
            title: 'Retail Id',
            nullable: true,
            type: InputTypes.Number
        },
        {
            name: 'RetailCode',
            title: 'Retail Code'
        },
        {
            name: 'Group',
            title: 'Player Group',
            type: InputTypes.Select,
            exact: true,
            nullable: true,
            data: {
                source: 'myPlayerGroups',
                key: 'Id',
                title: ['Name']
            }
        },
        CurrencyFilterField,
        {
            name: 'Retails',
            title: 'Only Retails',
            type: InputTypes.Bool
        }
    ]
};

export const resultModel = {
    fields: [
        {
            name: 'Created',
            title: 'Registration Date',
            type: InputTypes.DateTime
        },
        {
            name: 'Operator',
            title: 'Operator'
        },
        {
            name: 'RetailName',
            title: 'Retail'
        },
        {
            name: 'Group',
            title: 'Group'
        },
        {
            name: 'PlayerId',
            title: 'Player Id'
        },
        {
            name: 'Agent',
            title: 'Device',
            render: (val, row) => {
                return isMobile(row.Agent);
            }
        },
        {
            name: 'Username',
            title: 'Username',
            render: (val, row) => {
                return <a style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => {
                    gotoProfile(row.PlayerId);
                }}>{val}</a>;
            }
        },
        {
            name: 'Promotion',
            title: 'Promotion'
        },
        {
            name: 'Balance',
            title: 'Balance',
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
        },
        {
            name: 'BonusBalance',
            title: 'Bonus Balance',
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
        }
    ]
};


export const ReportPlayerWithoutBetsSportBetting = () => {
    const user = useSelector((state) => state.user.loginInformation);
    const singleOperator = user.UserPrivileges.Operators.length == 1;


    return <FluxSearch
        model={searchModel}
        endpoint={ReportEndpoints.SearchPlayersWithoutSportBets}
        resultModel={resultModel}
        onPrepareResultModel={(model) => {
            var copy = { ...model };

            if (singleOperator) {
                model.fields.find(x => x.name == 'Operator').hidden = true;
            }
            return copy;
        }}
    />;
};
