import { addDays, addHours, addMonths, startOfMonth, startOfWeek } from 'date-fns';
import { lang } from '../Localization/language';

const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const shortDays = {
    'Sunday': 'Sun',
    'Monday': 'Mon',
    'Tuesday': 'Tue',
    'Wednesday': 'Wed',
    'Thursday': 'Thu',
    'Friday': 'Fri',
    'Saturday': 'Sat'
};
export const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];



export const convertTimeZoneToTimeDiff = (zone) => {
    if (!zone) return 0;
    var z = zone.split(' ')[1].split(':')[0];
    return parseInt(z);
};

export const convertTZ = (date, tzString) => {
    return (typeof date === 'string' ? new Date(date) : date).toLocaleString('en-Gb', { timeZone: tzString, hour12: false });
};

export const getTime = (date) => {
    var data = date.split(' ')[1].split(':');
    var hours = data[0];
    var minutes = data[1];
    var time = hours.toString().padStart(2, '0') + ':' + minutes.padStart(2, '0');
    return time;
};

export const DateHelpers = {
    addDay: (date, diff) => {
        return addDays(date, diff);
    },
    addMonth: (date, diff) => {
        return addMonths(date, diff);
    },
    addHours: (date, diff) => {
        return addHours(date, diff);
    },
    offset: () => {
        var now = new Date();
        return (now.getTimezoneOffset() / 60) * -1;
    },
    timeZone: () => {
        return window.user.timeZoneConverted;
    },
    timeDiff: () => {
        if (!window.user || window.user.timeZoneConverted == DateHelpers.offset()) {
            return window.user.timeZoneConverted * -1;
        }
        return 0;
    },
    today: () => {
        var now = new Date();
        var date = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);

        return date;
    },
    tonight: () => {
        var now = new Date();
        var date = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59);

        return date;
    },
    yesterday: () => {
        var now = new Date();
        var date = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);

        return addHours(addDays(date, -1), 0);
    },
    startOfWeek: () => {
        var weekStart = startOfWeek(new Date());
        if (!window.user || window.user.timeZoneConverted == DateHelpers.offset()) {
            return addHours(weekStart, DateHelpers.offset());
        }
        return addHours(weekStart, 0);
    },
    startOfThisMonth: () => {
        var monthStart = startOfMonth(new Date());
        
        return addHours(new Date(monthStart.getFullYear(), monthStart.getMonth(), monthStart.getDate()), 0);
    },
    getTime: (date) => {
        if (!date) return;

        let parsedDate;
        if (typeof date === 'string') {
            parsedDate = new Date(date);
        } else if (date instanceof Date) {
            parsedDate = date;
        } else {
            return '';
        }

        return parsedDate.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });
    },
    getDay: (date, abbr = false) => {
        return abbr ? shortDays[DateHelpers.getDay(date)] : days[date.getDay()];
    },
    getFormatedDateWithMonth: (date) => {
        var monthName = lang(months[date.getMonth()]); // 'July' (or current month)

        return {
            date: date,
            formatted: `${date.getDate()} ${monthName}, ${date.getUTCFullYear()}`,
            day: DateHelpers.getDay(date),
            month: monthName,
            time: DateHelpers.getTime(date),
            year: date.getUTCFullYear(),
            shortDay: lang(shortDays[DateHelpers.getDay(date)])
        };
    }
};


window.ddd = DateHelpers;