import React, { useEffect, useState } from 'react';
import { API } from '../../../../v2/Lib/Api/Api';
import { Button } from '../../../components/button/Button';
import { FluxCard } from '../../../components/card/FluxCard';
import { FluxForm } from '../../../components/form/FluxForm';
import { Icon } from '../../../components/icon/Icon';
import { FluxNotification, NotificationType } from '../../../components/notification/FluxNotification';
import { Table } from '../../../components/table/Table';
import { PlayerEndpoints } from '../../../lib/API/Endpoints';
import { lang } from '../../../lib/Localization/language';

const model = {
    fields: [
        {
            name: 'Id',
            title: 'Id',
            nullable: true,
            hidden: true
        },
        {
            name: 'Name',
            title: 'Name',
            required: true
        },
        {
            name: 'Description',
            title: 'Description',
            required: true
        }
    ]
};

export const PlayerGroups = () => {
    const [saved, setSaved] = useState(null);
    const [addNew, setAddNew] = useState(false);
    const [groups, setGroups] = useState([]);
    const [group, setGroup] = useState(null);

    useEffect(() => {
        fetchGroups();
    }, []);

    const fetchGroups = () => {
        API.post(PlayerEndpoints.GetPlayerGroups, null, 'Fetching player groups', 'Unable to fetch groups').then((result) => {
            setGroups(result.result.filter((a) => !a.Retail));
        });
    };

    return <flex className='vertical gap-10'>
        {!addNew && !saved && <FluxCard>
            <Button className='align-left' title='Add new' onClick={() => { setAddNew(true); setGroup(null); }} />
        </FluxCard>}
        {saved && <FluxCard>
            <flex className='vertical gap-10'>
                <FluxNotification type={NotificationType.Success}>
                    <Icon icon='check-circle' />
                    <span>{lang('Group saved.')}</span>
                </FluxNotification>
                <buttons>
                    <Button title='Create Another' onClick={() => {
                        setSaved(null);
                    }} />
                </buttons>
            </flex>
        </FluxCard>}
        {addNew && <FluxCard title={group?.Id ? 'Edit group' : 'Add new group'}>
            <FluxForm
                model={model}
                data={group}
                endpoint={PlayerEndpoints.SavePlayerGroup}
                confirm={true}
                confirmTitle={group?.Id ? 'Edit group' : 'Create new group'}
                confirmMessage={'Please confirm before ' + (group?.Id ? 'editing' : 'creating new') + ' group'}
                onCancel={() => {
                    setAddNew(null);
                    setGroup(null);
                }}
                onSubmitComplete={(data) => {
                    setAddNew(null);
                    setGroup(null);
                    fetchGroups();
                    setSaved(data.result);
                }} />
        </FluxCard>}
        <FluxCard title='Groups'>
            <Table model={model} data={groups} className='fx-borders fx-shadow'
                buttons={(row) => {
                    if (row.Name != 'New Player') {
                        return <flex className='gap-10'>
                            <Button title='Edit' className='success' onClick={() => {
                                setGroup(row);
                                setAddNew(true);
                            }} />
                        </flex>;
                    }
                }} />
        </FluxCard>
    </flex>;
};
