/* eslint-disable react/display-name */

import '../../../../components/sports/RiskManagement/RiskLevels/_riskLevelConfiguration.scss';
import './_ticker.scss';

import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal } from '../../../../components/application/FluxApp';
import { Button } from '../../../../components/button/Button';
import { FluxCard } from '../../../../components/card/FluxCard';
import { currency } from '../../../../components/currency/Currency';
import { InputTypes } from '../../../../components/form/InputTypes';
import { Icon } from '../../../../components/icon/Icon';
import { ConfirmationModal } from '../../../../components/modal/ConfirmationModal/ConfirmationModal';
import { FluxNotice } from '../../../../components/notification/FluxNotice';
import { getLookupKey } from '../../../../components/sports/RiskManagement/RiskLevels/RiskLevelLimitsConfiguration';
import { Table } from '../../../../components/table/Table';
import { API } from '../../../../lib/API/Api';
import { RiskLevelEndpoints, SportRiskLevelEndpoints } from '../../../../lib/API/Endpoints';
import { SportsTicketTypes } from '../../../../lib/Enums/BettingEnums';
import { NotifyWithSound } from '../../../../lib/Helpers/Sound/SoundHelper';
import { lang } from '../../../../lib/Localization/language';
import { TicketId } from '../../../../lib/Models/TicketModels';
import { SearchPlayerBetHistory } from '../../../Players/SearchPlayerBetHistory/SearchPlayerBetHistory';
import { BetMonitorSocketContext } from '../MonitorTickets/BetmonitorSocket';
import { TickerFilters } from './TickerFilters';
import { gotoProfile } from '../../../../lib/Helpers/NavigationHelper';


const SelectionStatus = {
    0: <Icon icon='square' type='fa-regular' />,
    2: 'Refund',
    3: <Icon icon='times-square' type='fa-regular' />,
    4: <Icon icon='check-square' type='fa-regular' />,
    5: 'Cashout',
    6: 'Cancel'
};


export const getMatchTime = (time) => {
    const minutes = Math.floor(time / 60);
    return minutes;
};



const tickerModel = {
    fields: [
        {
            name: 'Ticket',
            title: '',
            render: () => {
                return <Button className='success' onClick={(e) => {
                    e.target.parentNode.click(e);
                }}>
                    <i className='fa-regular fa-gear'></i>
                </Button>;
            }
        },
        {
            name: 'ticketDate',
            title: 'Date',
            render: (val, row) => {
                var date = new Date(row.ticketDate);
                return <flex className='vertical'>
                    <span>{date.toLocaleDateString('en-Gb', { hours12: false })}</span>
                    <span>{date.toLocaleTimeString('en-Gb', { hours12: false })}</span>
                </flex>;
            }
        },
        {
            name: 'ticketId',
            title: 'Ticket',
            render: (val, row, field, context, tableContext) => {
                row.Product = 0;
                return <TicketId id={val} onChange={(ticket) => {
                    var data = tableContext.getData();
                    data = data.filter(x => x.ticketId != ticket.ticketId);
                    tableContext.setData(data);
                }} />;
            }
        },
        {
            name: 'username',
            title: 'Player',
            render: (val, row) => {
                return <a style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => {
                    gotoProfile(row.playerId);
                }}>{val}</a>;
            }
        },
        {
            name: 'betType',
            title: 'Ticket Type',
            render: (val, row) => {
                return SportsTicketTypes[row.ticketType];
            }
        },
        {
            name: 'selection',
            title: 'Selection',
            renderHeader: () => {
                return <flex className='vertical gap-5'>
                    <span>Selections</span>
                    <flex className='gap-5 border-top'>
                        <span className='selection-level hidden'>Risk Levels</span>
                        <span className='selection-live-pre'>Live/Pre</span>
                        <span className='selection-event'>Event</span>
                        <span className='selection-event-date'>Date</span>
                        <span className=''>Market, Selection</span>
                        <span className='align-right selection-price'>Price</span>
                        <span className='selection-score'>Score</span>
                        <span className='selection-time'>Time</span>
                    </flex>
                </flex>;
            },
            render: (val, row, field, context) => {
                return <flex className='vertical gap-10'>
                    {
                        Object.values(row.selections).map(x => {
                            var status = x.status;
                            var converted = x.riskLevel ? getLookupKey(x.riskLevel) : [];
                            const result = converted.map((x, index) => {
                                return <flex key={index} className={`risk-level-${x}`}>{x}</flex>;
                            });

                            var level = <flex className='gap-1 selection-level hidden'>
                                {result}
                            </flex>;

                            var score = context.scores ? context.scores[x.eventExternalId] : '{}';
                            if (score) {
                                score = JSON.parse(score);
                            }
                            var homeScore = '';
                            var awayScore = '';

                            var time = '';
                            if (score?.Results) {
                                homeScore = score.Results.find(x => x.Position == '1').Value;
                                awayScore = score.Results.find(x => x.Position == '2').Value;
                                time = getMatchTime(score.Time);
                            }

                            var sportLevel = converted[0];
                            var regionLevel = converted[1];
                            var leagueLevel = x.leagueLevel;
                            var date = new Date(x.date);
                            return <flex className='vertical' key={x.id}>
                                <flex className='gap-5'>
                                    <flex className='vertical'>
                                        <flex className='gap-5'>
                                            {level}
                                            <span className='selection-live-pre bold'>{x.live ? 'live' : 'prematch'}</span>
                                            <flex className='selection-event gap-5'>
                                                {SelectionStatus[status]}
                                                <span>{x.eventName}</span>
                                            </flex>
                                        </flex>
                                        <flex className='gap-5'>
                                            <span className={`small meta-level-${sportLevel}`}>{x.sport}</span>
                                            /
                                            <span className={`small meta-level-${regionLevel}`}>{x.region}</span>
                                            /
                                            <span className={`small meta-level-${leagueLevel}`}>{x.league}</span>
                                        </flex>
                                    </flex>
                                    <flex className='selection-event-date vertical'>
                                        <span>{date.toLocaleDateString('en-Gb', { hours12: false })}</span>
                                        <span>{date.toLocaleTimeString('en-Gb', { hours12: false })}</span>
                                    </flex>
                                    <flex className='vertical'>
                                        <span className='bold'>{x.marketName}</span>
                                        <span>{x.selectionName}{x.specifiers ? ` (${x.specifiers})` : ''}</span>
                                    </flex>
                                    <span className='align-right selection-price'>{x.price.toFixed(2)}</span>
                                    <flex className='selection-score'>
                                        <span>{homeScore}</span>
                                        -
                                        <span>{awayScore}</span>
                                    </flex>
                                    <flex className='selection-time'>
                                        <span>{time}</span>
                                    </flex>
                                </flex>
                            </flex>;
                        })
                    }
                </flex>;
            }
        },
        {
            name: 'stake',
            title: 'Total Stake',
            render: (val, row) => {
                return currency(row.amount, 2, row.currency);
            }
        },
        {
            name: 'possibleWin',
            title: 'Possible Win',
            render: (val, row) => {
                return currency(row.possibleWin, 2, row.currency);
            }
        },
        {
            name: 'ipAddress',
            title: 'IP'
        },
        {
            name: 'PostponePayment',
            title: 'Investigated',
            type: InputTypes.Bool,
            render: (val, row) => {
                if (row.postponePayment) {
                    return <flex className='vertical'>
                        <span className='bold'>{lang('Yes')}</span>
                        <span>{row.investigationLevels}</span>
                    </flex>;
                }
            }
        }
    ]
};

export const BetTicker = () => {
    const context = useContext(BetMonitorSocketContext);

    const socketData = useSelector((state) => state.data?.sockets?.sport);
    socketData;

    const [connected, setConnected] = useState(context.connected);

    const user = useSelector((state) => state.user).loginInformation;
    const operatorId = user.UserPrivileges.PointOfView;

    const [scores, setScores] = useState({});
    const [tickets, setTickets] = useState([]);


    const [newTickets, setNewTickets] = useState(null);
    const [newCashoutTicket, setCashoutTicket] = useState(null);
    const [resultingTicket, setResultedTicket] = useState(null);
    const [manualSettledTicket, setManualSettlementTicket] = useState(null);

    const [newScores, setNewScores] = useState(null);

    const [selectedTab, selectTab] = useState('all');
    const [sportStats, setSportStats] = useState({});
    const [regionStats, setRegionStats] = useState({});
    const [leagueStats, setLeagueStats] = useState({});
    const [eventStats, setEventStats] = useState({});

    const [filters, setFilters] = useState(null);

    const [stats, setStats] = useState(null);


    const [ready, setReady] = useState(false);
    const [showFiltersMenu, toggleFilters] = useState(false);

    const [soundError, setSoundError] = useState(null);

    const [selectedTicket, selectTicket] = useState(null);


    useEffect(() => {
        context.eventHandlers({
            onConnected: () => setConnected(true),
            onNewTicket: (t) => {
                setNewTickets(t);
            },
            onCashoutTicket: (request) => setCashoutTicket(request),
            onCloseTicket: (request) => setResultedTicket(request),
            onRemoveTicket: () => {
            },
            onManualSettlement: (ticketId) => {
                setManualSettlementTicket(ticketId);
            },
            onScore: (scoreData) => {
                setNewScores(scoreData);
            }
        });
    }, []);

    useEffect(() => {
        if (!connected) return;

        NotifyWithSound('https://cdn-d.tentangle.com/bo/sounds/notification1.mp3', 100, false).catch(() => {
            setSoundError(<flex className='vertical gap-5'>
                <span>{lang('Playing sounds after window refresh requires user gesture to click!')}</span>
                <flex>
                    <Button title='Enable sounds' className='success' onClick={() => {
                        setSoundError(false);
                        NotifyWithSound('https://cdn-d.tentangle.com/bo/sounds/notification1.mp3', 100, false).catch(() => {

                        });

                    }} />
                </flex>
            </flex>);
        });
        loadTickets();

        var interval = setInterval(() => {
            API.post(`${RiskLevelEndpoints.Stats}/${operatorId}`, null, null, null, 'monitor', false, false, true).then((result) => {
                setStats(result.result);
            });
        }, 5000);

        return () => {
            clearInterval(interval);
        };
    }, [connected]);

    useEffect(() => {
        if (!newTickets) return;
        var copy = newTickets.concat(tickets);
        setTickets(copy.sort((a, b) => new Date(b.entryDate) - new Date(a.entryDate)));
        NotifyWithSound('https://cdn-d.tentangle.com/bo/sounds/notification1.mp3', 100, false);

    }, [newTickets]);

    useEffect(() => {
        if (!newCashoutTicket) return;
        var copy = tickets.filter(x => x.ticketId != newCashoutTicket.ticketId);
        setTickets(copy);
    }, [newCashoutTicket]);

    useEffect(() => {
        if (!resultingTicket) return;
        var copy = tickets.filter(x => x.ticketId != resultingTicket.ticketId);
        setTickets(copy);
    }, [resultingTicket]);

    useEffect(() => {
        if (!manualSettledTicket) return;
        var copy = tickets.filter(x => x.ticketId != manualSettledTicket);
        setTickets(copy);
    }, [manualSettledTicket]);


    useEffect(() => {
        if (!newScores) return;
        var copy = { ...scores };
        copy[newScores.id] = newScores.score;
        setScores(copy);
    }, [newScores]);

    useEffect(() => {
        if (!filters) return;
        loadTickets();
    }, [filters]);

    const loadTickets = () => {
        var copy = filters ?? {};

        Object.keys(filters ?? {}).map(x => {
            if (Array.isArray(filters[x])) {
                if (filters[x].length == 0) {
                    delete copy[x];
                }
            }
        });

        API.post(`${RiskLevelEndpoints.Ticker}/${operatorId}`, copy).then((result) => {
            setSportStats(result.result?.sports);
            setRegionStats(result.result?.regions);
            setLeagueStats(result.result?.leagues);
            setEventStats(result.result?.events);

            setScores(result.result?.scores);
            setTickets(result.result?.tickets.sort((a, b) => new Date(b.entryDate) - new Date(a.entryDate)));
            setReady(true);
        });
    };



    const investigate = (type) => {
        var description = `${selectedTicket.ticketId} will be investigated and settled manually!`;
        var key = selectedTicket.ticketId;
        var add = true;

        switch (selectedTicket.postponePayment) {
            case true:
                switch (type) {
                    case 'ticket':
                        description = `${selectedTicket.ticketId} will be removed from investigation!`;
                        add = false;
                        break;
                    case 'player':
                        description = `All tickets from player ${selectedTicket.Username} will be removed from investigation!`;
                        key = selectedTicket.PlayerId;
                        add = false;
                        break;
                    case 'group':
                        description = `All tickets from group ${selectedTicket.PlayerGroupName} will be removed from investigation!`;
                        key = selectedTicket.PlayerGroupName;
                        add = false;

                        break;
                    case 'ip':
                        description = `All tickets from ip ${selectedTicket.IPAddress} will be removed from investigation!`;
                        key = selectedTicket.IpAddress;
                        add = false;

                        break;
                }
                break;
            case false:
                switch (type) {
                    case 'player':
                        description = `All tickets from player ${selectedTicket.Username} will be investigated and settled manually!`;
                        key = selectedTicket.PlayerId;
                        break;
                    case 'group':
                        description = `All tickets from group ${selectedTicket.PlayerGroupName} will be investigated and settled manually!`;
                        key = selectedTicket.PlayerGroupName;
                        break;
                    case 'ip':
                        description = `All tickets from ip ${selectedTicket.IpAddress} will be investigated and settled manually!`;
                        key = selectedTicket.IpAddress;
                        break;
                }
                break;
        }


        Modal.open(<ConfirmationModal title='Invesitage ticket' onConfirm={() => {
            API.post(`${add ? SportRiskLevelEndpoints.AddToInvestigatation : SportRiskLevelEndpoints.RemoveFromInvestigation}/${operatorId}`, {
                type: type,
                key: key,
                operatorId: operatorId
            }).then(() => {
                selectTicket(null);
                loadTickets();
            });
        }}>
            <FluxNotice type='warning' title='Invesitage ticket' description={description} />
        </ConfirmationModal>);
    }

    const renderTicker = () => {
        return <flex className='vertical gap-5'>
            {soundError && <FluxNotice type='warning' title='Sound error' description={soundError} />}
            <flex className='padding gap-5'>
                <Button title={showFiltersMenu ? 'Close Filters' : 'Open Filters'} className={'success'} onClick={() => toggleFilters(!showFiltersMenu)} />
            </flex>
            {ready && <TickerFilters visible={showFiltersMenu}
                sports={sportStats}
                regions={regionStats}
                leagues={leagueStats}
                events={eventStats}
                onFilter={(data) => {
                    setFilters(data);
                }}
            />}
            <flex className='vertical hidden'>
                <flex className='gap-10 borders padding'>
                    <span>{lang('Sport Filters')}</span>
                    {
                        Object.keys(sportStats).map(x => {
                            return <flex className='gap-5 ticket-sports' key={x}>
                                <span>{x.split(':').reverse()[0]}</span>
                            </flex>;
                        })
                    }
                </flex>
                <flex className='gap-10 borders padding'>
                    <span>{lang('Region Filters')}</span>
                    {
                        Object.keys(regionStats).map(x => {
                            return <flex className='gap-5 ticket-sports' key={x}>
                                <span>{x.split(':').reverse()[0]}</span>
                            </flex>;
                        })
                    }
                </flex>
                <flex className='gap-10 borders padding'>
                    <span>{lang('League Filters')}</span>
                    {
                        Object.keys(leagueStats).map(x => {
                            return <flex className='gap-5 ticket-sports' key={x}>
                                <span>{x.split(':').reverse()[0]}</span>
                            </flex>;
                        })
                    }
                </flex>
                <flex className='gap-10 borders padding'>
                    <span>{lang('Event Filters')}</span>
                    {
                        Object.keys(eventStats).map(x => {
                            return <flex className='gap-5 ticket-sports' key={x}>
                                <span>{x.split(':').reverse()[0]}</span>
                            </flex>;
                        })
                    }
                </flex>

            </flex>
            <flex className='padding gap-5 border'>
                <flex className={`align-right gap-5 ${selectedTicket ? '' : 'disabled'}`}>
                    <span>{lang('Investigate')}</span>
                    {selectedTicket && (!selectedTicket.postponePayment) && <Button title='This ticket' className={'warning'} onClick={() => investigate('ticket')} />}
                    {selectedTicket && selectedTicket.postponePayment && <Button title='Remove ticket from investigation' className={'success'} onClick={() => investigate('ticket')} />}
                    {selectedTicket && <Button title={lang('%1\'s tickets', [selectedTicket.username])} className={'warning'} onClick={() => investigate('player')} />}
                    {selectedTicket && <Button title={lang('%1\'s tickets', [selectedTicket.playerGroupName])} className={'warning'} onClick={() => investigate('group')} />}
                    {selectedTicket && <Button title={lang('IP %1\'s tickets', [selectedTicket.ipAddress])} className={'warning'} onClick={() => investigate('ip')} />}
                </flex>
            </flex>
            <Table model={tickerModel} data={tickets} context={{ scores: scores }} className={'border'} onRowClick={(row) => {
                selectTicket(row);
            }} />
        </flex>;
    };


    return <FluxCard title='Ticker' className={`no-padding content${connected ? '' : ' disabled'}`}
        buttons={<flex className='gap-10 align-right'>
            <Button className={selectedTab == 'all' ? 'active' : ''} title='All Bets' onClick={() => selectTab('all')} />
            <Button className={selectedTab == 'cashouts' ? 'active' : ''} title='Cashouts' onClick={() => selectTab('cashouts')} />
            <Button className={selectedTab == 'investigated' ? 'active' : ''} title='Investigated' onClick={() => selectTab('investigated')} />
            <Button className={selectedTab == 'resulted' ? 'active' : ''} title='Resulted' onClick={() => selectTab('resulted')} />
        </flex>}
        footer={<flex className='gap-20 fit'>
            <flex className='vertical'>
                <flex className='gap-5'>
                    <span className='bold'>{lang('Open tickets')}</span>
                    <span>{Object.values(stats ?? {}).reduce((acc, cur) => acc + cur.openTickets.total, 0)}</span>
                </flex>
                {
                    Object.keys(stats ?? {})?.map((currencyData, index) => {
                        const currencyStats = stats[currencyData];
                        return <flex key={currencyData} className='gap-10'>
                            <flex className='gap-5'>
                                <span className='bold'>{lang('Total Ticket')}</span>
                                <span>{currencyStats?.openTickets.total}</span>
                            </flex>
                            <flex className='gap-5'>
                                <span className='bold'>{lang('Stake')}</span>
                                <span>{currency(currencyStats?.openTickets.totalStake, 2, currencyData)}</span>
                            </flex>
                            <flex className='gap-5'>
                                <span className='bold'>{lang('Possible Win')}</span>
                                <span>{currency(currencyStats?.openTickets.totalPossibleWin, 2, currencyData)}</span>
                            </flex>
                        </flex>
                    })
                }
            </flex>
            <flex className='vertical align-right'>
                <flex className='gap-5'>
                    <span className='bold'>{lang('Single Last Leg Tickets')}</span>
                    <span>{Object.values(stats ?? {}).reduce((acc, cur) => acc + cur.sllTickets.total, 0)}</span>
                </flex>
                {
                    Object.keys(stats ?? {})?.map((currencyData, index) => {
                        const currencyStats = stats[currencyData];
                        return <flex key={currencyData} className='gap-10'>
                            <flex className='gap-5'>
                                <span className='bold'>{lang('Total SLL')}</span>
                                <span>{currencyStats?.sllTickets.total}</span>
                            </flex>
                            <flex className='gap-5'>
                                <span className='bold'>{lang('Stake')}</span>
                                <span>{currency(currencyStats?.sllTickets.totalStake, 2, currencyData)}</span>
                            </flex>
                            <flex className='gap-5'>
                                <span className='bold'>{lang('Possible Win')}</span>
                                <span>{currency(currencyStats?.sllTickets.totalPossibleWin, 2, currencyData)}</span>
                            </flex>
                        </flex>
                    })
                }
            </flex>
        </flex>}>
        {selectedTab == 'all' && renderTicker()}
        {selectedTab == 'cashouts' && <SearchPlayerBetHistory cashout />}
        {selectedTab == 'investigated' && <SearchPlayerBetHistory pending />}
        {selectedTab == 'resulted' && <SearchPlayerBetHistory resolved />}
    </FluxCard>;
};