/* eslint-disable react/display-name */
import React from 'react';
import { useSelector } from 'react-redux';
import { FluxSearch } from '../../../components/search/FluxSearch';
import { ReportEndpoints } from '../../../lib/API/Endpoints';
import { DateHelpers } from '../../../lib/Helpers/DateHelpers';
import { InputTypes } from '../../../components/form/InputTypes';
import { footerCurrencyValues } from '../../../lib/Helpers/Reports/Footer';
import { Currency } from '../../../components/currency/Currency';


const model = {
    fields: [
        {
            name: 'OperatorId',
            title: 'Operator',
            type: InputTypes.Select,
            exact: true,
            nullable: true,
            data: {
                source: 'operators',
                key: 'Id',
                title: ['Name']
            }
        },
        {
            name: 'EntryDate',
            title: 'Date',
            type: InputTypes.DateTime,
            nullable: true,
            between: true,
            value: {
                start: DateHelpers.startOfWeek(),
                end: DateHelpers.tonight()
            }
        },
        {
            name: 'ExternalTicketId',
            title: 'Ticket Id',
            type: InputTypes.Number
        },
        {
            name: 'EventId',
            title: 'Event Id',
            exact: true,
        },
        {
            name: 'PlayerId',
            title: 'Player Id',
            type: InputTypes.Number
        },
        {
            name: 'Username'
        },
        {
            name: 'Code'
        },
        {
            name: 'RetailId',
            title: 'Retail Id',
            nullable: true,
            type: InputTypes.Number
        },
        {
            name: 'RetailCode',
            title: 'Retail Code'
        },
        {
            name: 'Group',
            title: 'Player Group',
            type: InputTypes.Select,
            exact: true,
            nullable: true,
            data: {
                source: 'myPlayerGroups',
                key: 'Id',
                title: ['Name']
            }
        }
    ]
};

const resultModel = {
    fields: [
        {
            name: 'Slips',
            title: 'Total Slips'
        },
        {
            name: 'Currency'
        },
        {
            name: 'Bet',
            title: 'Stakes',
            render: (val, row) => <Currency amount={val} sign={row.Currency} />,
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)

        },
        {
            name: 'BetsConverted',
            title: 'Stakes in (USD)',
            render: (val) => <Currency amount={val} sign={'USD'} />,
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        },
        {
            name: 'Win',
            title: 'Win',
            render: (val, row) => <Currency amount={val} sign={row.Currency} />,
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)

        },
        {
            name: 'WinsConverted',
            title: 'Wins in (USD)',
            render: (val) => <Currency amount={val} sign={'USD'} />,
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        },
        {
            name: 'GGR',
            title: 'GGR',
            type: InputTypes.Number,
            render: (val, row) => <Currency amount={row.BetsConverted - row.WinsConverted} sign={'USD'} />,
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        }
    ]
};



export const ReportHorseGreyhoundRevenues = () => {
    const user = useSelector((state) => state.user.loginInformation);
    const singleOperator = user.UserPrivileges.Operators.length == 1;


    return <FluxSearch
        model={model}
        endpoint={ReportEndpoints.HorseGreyhoundSummaryReport}
        resultModel={resultModel}
        onPrepareModel={(model) => {
            var copy = { ...model };

            if (singleOperator) {
                var operatorField = copy.fields.find(x => x.name == 'OperatorId');
                if (operatorField) {
                    operatorField.hidden = true;
                }
            }
            return copy;
        }}
        onPrepareResultModel={(model) => {
            var copy = { ...model };

            if (singleOperator) {
                var operatorField = copy.fields.find(x => x.name == 'OperatorId');
                if (operatorField) {
                    operatorField.hidden = true;
                }
            }
            return copy;
        }}
    />;
};
