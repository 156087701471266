/* eslint-disable react/display-name */
import React from 'react';
import { ReportEndpoints } from '../../../../v2/Lib/Api/Api';
import { InputTypes } from '../../../components/form/InputTypes';
import { FluxSearch } from '../../../components/search/FluxSearch';
import { DateHelpers } from '../../../lib/Helpers/DateHelpers';
import { Currency } from '../../../components/currency/Currency';
import { footerCurrencyValues } from '../../../lib/Helpers/Reports/Footer';
import './_report-providers-transactions.scss';
import { CurrencyFilterField } from '../../../lib/Models/CurrencyModel';

export const searchModel = {
    fields: [
        {
            name: 'OperatorId',
            title: 'Operator',
            type: InputTypes.Select,
            exact: true,
            nullable: true,
            data: {
                source: 'operators',
                key: 'Id',
                title: ['Name']
            }
        },
        {
            name: 'TransactionDate',
            title: 'Date',
            type: InputTypes.DateTime,
            nullable: true,
            between: true,
            value: {
                start: DateHelpers.startOfWeek(),
                end: DateHelpers.tonight()
            }
        },
        {
            name: 'ProviderId',
            title: 'Providers',
            type: InputTypes.Select,
            nullable: true,
            multiple: true,
            data: {
                source: 'providers/gaming',
                key: 'id',
                title: ['display']
            }
        },
        CurrencyFilterField
    ]
};

export const resultModel = {
    fields: [
        {
            name: 'Provider',
            type: InputTypes.Text
        },
        {
            name: 'Currency',
            title: 'Currency'
        },
        {
            name: 'Deposit',
            title: 'Deposit',
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        },
        {
            name: 'DepositCount',
            title: 'Deposit Count',
            type: InputTypes.Number
        },
        {
            name: 'Withdraw',
            title: 'Withdraw',
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        },
        {
            name: 'WithdrawCount',
            title: 'Withdraw Count',
            type: InputTypes.Number
        }
    ]
};

export const ReportProvidersTransactions = () => {
    return <FluxSearch
        className='report-providers-transactions'
        model={searchModel}
        endpoint={ReportEndpoints.TransactionsProviderReport}
        resultModel={resultModel}
    />;
};
