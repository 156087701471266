import React from 'react';
import PropTypes from 'prop-types';

import { Icon } from '../../icon/Icon';

export const BusyOverlay = (props) => {
    return <overlay className='padding surface low'>
        <Icon icon='spinner' className='fa-spin margin-right' /> {props.title} {props.children}
    </overlay>;
};

BusyOverlay.propTypes = {
    title: PropTypes.string,
    children: PropTypes.node
};
