/* eslint-disable react/display-name */

import React, { useEffect, useState } from 'react';

import { FluxCard } from '../../../../components/card/FluxCard';
import { PromotionEndpoints } from '../../../../lib/API/Endpoints';
import { API } from '../../../../lib/API/Api';
import { lang } from '../../../../lib/Localization/language';
import ReactSelect from 'react-select';
import { FluxInput } from '../../../../components/form/Components/FluxInput';
import { InputTypes } from '../../../../components/form/InputTypes';


export const FirstTimeFreeBetSettings = (props) => {
    const { promotion, onChange } = { ...props };
    const [configuration, setConfiguration] = useState(null);

    const [freebets, setFreeBets] = useState(null);

    useEffect(() => {
        API.post(PromotionEndpoints.List, {}).then((result) => {
            var p = result.result.filter(x => x.Type == 17 || x.Type == 13).sort((a, b) => a.Name > b.Name ? 1 : -1);
            setFreeBets(p);
        });
    }, []);


    useEffect(() => {
        if (!freebets) return;
        var c = { ...promotion.Configuration };
        if (!c.Award) {
            c.Award = {
                Promotion: {
                    Id: 0,
                    Type: 0,
                    Amount: 0,
                    Expires: 0
                }
            };
        }

        setConfiguration(c);
    }, [freebets]);


    useEffect(() => {
        if (!configuration) return;
        var copy = { ...promotion };
        copy.Configuration = configuration;
        onChange(copy);
    }, [configuration]);



    if (!configuration) return <></>;
    if (!freebets) return <></>;


    var freebetOptions = freebets.map(x => {
        return {
            label: x.Name,
            value: x.Id
        };
    });


    var selected = freebets.find(x => x.Id == configuration.Award.Promotion.Id);
    var selectedFreeBetOption = selected ? freebetOptions.find(x => x.value == selected.Id) : null;
    return <FluxCard>
        <flex className='vertical gap-10' key={configuration.Award.Promotion.Id}>
            <flex className='vertical'>

                <span>{lang('Please select a free bet for award')}</span>
                <ReactSelect
                    className='react-select'
                    styles={{ menuPortal: base => ({ ...base, zIndex: 100 }) }}
                    menuPortalTarget={document.body}
                    defaultValue={selectedFreeBetOption}
                    options={freebetOptions}
                    onChange={(e) => {
                        var promotion = freebets.find(x => x.Id == e.value);
                        var copy = { ...configuration };
                        copy.Award.Promotion = {
                            Id: promotion.Id,
                            Type: promotion.Type,
                            Amount: 0,
                            Expires: 0,
                            AutoTrigger: false
                        };
                        setConfiguration(copy);
                    }}
                    name={'freebet'}
                    hideSelectedOptions={false}
                    closeMenuOnSelect={true}
                    isClearable={false}
                    isSearchable={false}
                />
            </flex>
            <flex className='gap-20'>
                <flex className='vertical'>
                    <span>{lang('Amount')}</span>
                    <input type='number' defaultValue={configuration.Award.Promotion.Amount} onChange={(e) => {
                        var copy = { ...configuration };
                        let value = parseInt(e.target.value);
                        copy.Award.Promotion = {
                            Id: copy.Award.Promotion.Id,
                            Type: copy.Award.Promotion.Type,
                            Amount: value,
                            Expires: copy.Award.Promotion.Expires,
                            AutoTrigger: copy.Award.Promotion.AutoTrigger
                        };
                        setConfiguration(copy);
                    }} />
                </flex>
                <flex className='vertical'>
                    <span>{lang('Expire promotion after (n) days')}</span>
                    <input type='number' defaultValue={configuration.Award.Promotion.Expires} onChange={(e) => {
                        var copy = { ...configuration };
                        let value = parseInt(e.target.value);
                        copy.Award.Promotion = {
                            Id: copy.Award.Promotion.Id,
                            Type: copy.Award.Promotion.Type,
                            Amount: copy.Award.Promotion.Amount,
                            Expires: value,
                            AutoTrigger: copy.Award.Promotion.AutoTrigger
                        };
                        setConfiguration(copy);
                    }} />
                </flex>
                <flex className='vertical'>
                    <span>{lang('Automatically Trigger Award')}</span>
                    <FluxInput
                        field={{
                            type: InputTypes.Bool,
                            name: 'AutoTrigger',
                            value: configuration?.Award?.Promotion?.AutoTrigger ?? false,
                            data: {
                                AutoTrigger: configuration?.Award?.Promotion?.AutoTrigger ?? false
                            }
                        }} onChange={(field, value) => {
                            var copy = { ...configuration };
                            copy.Award.Promotion = {
                                Id: copy.Award.Promotion.Id,
                                Type: copy.Award.Promotion.Type,
                                Amount: copy.Award.Promotion.Amount,
                                Expires: copy.Award.Promotion.Expires,
                                AutoTrigger: value
                            };
                            setConfiguration(copy);
                        }} />
                </flex>
            </flex>
        </flex>
    </FluxCard>;
};