/* eslint-disable react/display-name */
import React from 'react';
import { useSelector } from 'react-redux';
import { ReportEndpoints } from '../../../../v2/Lib/Api/Api';
import { Currency } from '../../../components/currency/Currency';
import { InputTypes } from '../../../components/form/InputTypes';
import { FluxSearch } from '../../../components/search/FluxSearch';
import { ProviderGameTypes } from '../../../lib/Enums/GameEnums';
import { DateHelpers } from '../../../lib/Helpers/DateHelpers';
import { gotoProfile } from '../../../lib/Helpers/NavigationHelper';
import { footerCurrencyValues } from '../../../lib/Helpers/Reports/Footer';
import { calculateRTP } from '../../../lib/Helpers/RTPHelper';
import { CurrencyFilterField } from '../../../lib/Models/CurrencyModel';

export const searchModel = {
    fields: [
        {
            name: 'OperatorId',
            title: 'Operator',
            type: InputTypes.Select,
            nullable: true,
            data: {
                source: 'operators',
                key: 'Id',
                title: ['Name']
            }
        },
        {
            name: 'DateStart',
            title: 'Date',
            type: InputTypes.DateTime,
            nullable: true,
            between: true,
            value: {
                start: DateHelpers.startOfWeek(),
                end: DateHelpers.tonight()
            }
        },
        {
            name: 'PlayerId',
            title: 'Player Id',
            type: InputTypes.number,
        },
        {
            name: 'RetailId',
            title: 'Retail Id',
            nullable: true,
            type: InputTypes.Number
        },
        {
            name: 'RetailCode',
            title: 'Retail Code'
        },
        {
            name: 'Code',
            title: 'Code',
            type: InputTypes.Text,
        },
        {
            name: 'Username',
            title: 'Username',
            type: InputTypes.Text,
        },
        {
            name: 'ProviderId',
            title: 'Providers',
            type: InputTypes.Select,
            nullable: true,
            multiple: true,
            data: {
                source: 'providers/gaming',
                key: 'id',
                title: ['display']
            }
        },
        {
            name: 'LiveDealer',
            title: 'Live Dealer',
            exact: true,
            type: InputTypes.Bool
        },
        {
            name: 'GameType',
            title: 'Game Type',
            type: InputTypes.Select,
            values: ProviderGameTypes,
            nullable: true
        },
        {
            name: 'GameId',
            title: 'Game Id',
            type: InputTypes.Number,
        },
        {
            name: 'GameName',
            title: 'Game Name',
            type: InputTypes.Text,
        },
        CurrencyFilterField,
        {
            name: 'Retails',
            title: 'Only Retails',
            type: InputTypes.Bool
        }
    ]
};

export const resultModel = {
    fields: [
        {
            name: 'Operator'
        },
        {
            name: 'PlayerId',
            title: 'Player Id',
            type: InputTypes.Number,
        },
        {
            name: 'Code',
            title: 'Code'
        },
        {
            name: 'RetailName',
            title: 'Retail Name'
        },
        {
            name: 'Username',
            type: InputTypes.Text,
            render: (val, row) => {
                return <a style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => {
                    gotoProfile(row.PlayerId);
                }}>{val}</a>;
            }
        },
        {
            name: 'GameId',
            title: 'Game Id'
        },
        {
            name: 'GameName',
            title: 'Game Name'
        },
        {
            name: 'Provider'
        },
        {
            name: 'LiveDealer'
        },
        {
            name: 'GameType',
            title: 'Game Type',
            type: InputTypes.Select,
            values: ProviderGameTypes,
        },
        {
            name: 'Currency',
            type: InputTypes.Text
        },
        {
            name: 'Bet',
            type: InputTypes.Number,
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        },
        {
            name: 'Win',
            type: InputTypes.Number,
            render: (val, row) => <Currency amount={val} sign={row.Currency} showSign={false} />,
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        },
        {
            name: 'GGR',
            title: 'GGR',
            type: InputTypes.Number,
            render: (val, row) => <Currency amount={row.Bet - row.Win} sign={row.Currency} showSign={false} />,
            renderFooter: (val) => footerCurrencyValues(val),
            exportFooter: (val) => footerCurrencyValues(val, true)
        },
        {
            name: 'RTP',
            title: 'RTP',
            type: InputTypes.Number,
            render: (val, row) => calculateRTP(row)
        },
        {
            name: 'Round',
            title: 'Hands',
            type: InputTypes.Number
        }
    ],
    onRenderClassName: (row, className) => {
        if (row.Win > (Math.abs(row.Bet) - Math.abs(row.Cancel))) {
            className = 'player-win';
        } else {
            className = 'player-lost';
        }
        return className;
    }
};

export const ReportPlayerGamesSummary = () => {
    const user = useSelector((state) => state.user.loginInformation);
    const singleOperator = user.UserPrivileges.Operators.length == 1;


    return <FluxSearch
        model={searchModel}
        endpoint={ReportEndpoints.GamesPlayerSummaryReport}
        resultModel={resultModel}
        onPrepareResultModel={(model) => {
            var copy = { ...model };

            if (singleOperator) {
                model.fields.find(x => x.name == 'Operator').hidden = true;
            }
            return copy;
        }}
    />;
};
