import React from 'react';
import { FluxTab } from '../../../components/tab/FluxTab';
import { TurnoverRules } from './Components/TurnoverRules';
import { GeneralSettings } from './Components/GeneralSettings';
import { PayoutSettings } from './Components/PayoutSettings';
import { SportsSettings } from './Components/SportsSettings';
import { HourseGreyhoundSettings } from './Components/HourseGreyhoundSettings';
import { CasinoSettings } from './Components/CasinoSettings';
import { PaymentGatewayConfiguration } from './Components/PaymentGatewayConfiguration';
import { PromotionGroupSettings } from './Components/PromotionGroupSettings';
import { FluxNotice } from '../../../components/notification/FluxNotice';
import { FluxCard } from '../../../components/card/FluxCard';

export const DepositBonus = (props) => {
    const { promotion } = { ...props };
    var buttons = [
        {
            title: 'General Settings'
        },
        {
            title: 'Group Settings'
        },
        {
            title: 'Rules'
        },
        {
            title: 'Payment Providers'
        },
        {
            title: 'Payout Settings'
        },
        {
            title: 'Sports'
        },
        {
            title: 'Horse & Greyhound'
        },
        {
            title: 'Game Settings'
        }
    ];


    return <FluxTab buttons={buttons} className='gap-10 fx-shadow fx-radius rounded-buttons'>
        <GeneralSettings {...props} />
        <PromotionGroupSettings {...props} />
        <TurnoverRules {...props} />
        <PaymentGatewayConfiguration  {...props} />
        <PayoutSettings {...props} />
        <SportsSettings {...props} disabled={promotion?.Configuration?.deposit?.prizeType == 'FreeBet'} disabledReason={<FluxCard>
            <FluxNotice type='information' title='Sport configuration is not enabled' description={'Configuration should be completed using the selected awarded promotion settings!'} />
        </FluxCard>} />
        <HourseGreyhoundSettings  {...props} disabled={promotion?.Configuration?.deposit?.prizeType == 'FreeBet'} disabledReason={<FluxCard>
            <FluxNotice type='information' title='Horse configuration is not enabled' description={'Configuration should be completed using the selected awarded promotion settings!'} />
        </FluxCard>} />
        <CasinoSettings  {...props} disabled={promotion?.Configuration?.deposit?.prizeType == 'FreeBet'} disabledReason={<FluxCard>
            <FluxNotice type='information' title='Casino configuration is not enabled' description={'Selected prize does not match with casino product!'} />
        </FluxCard>} />
    </FluxTab>;
};